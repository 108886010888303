import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { AppOpeningAnimation } from "@swan-io/lake/src/components/AppOpeningAnimation";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { invariantColors } from "@swan-io/lake/src/constants/design";
import { useBoolean } from "@swan-io/lake/src/hooks/useBoolean";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  isNotEmpty,
  isNotNullishOrEmpty,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import swanLogo from "../assets/img/swan-app.svg";
import { ColorInput } from "../components/ColorInput";
import { CopyTextButton } from "../components/CopyTextButton";
import { DocumentationLink } from "../components/DocumentationLink";
import { ErrorView } from "../components/ErrorView";
import { ImageUploadInput } from "../components/ImageUploadInput";
import { TrackPressable } from "../components/TrackPressable";
import {
  GetConsentNotificationPageDocument,
  GetConsentNotificationPageQuery,
  ProbeConsentNotificationDocument,
  UpdateProjectConsentSettingsDocument,
} from "../graphql/exposed-internal";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { validateHexColor, validateUrl } from "../utils/validations";

const styles = StyleSheet.create({
  input: {
    flexGrow: 1,
  },
  phoneContainer: {
    width: 300,
  },
  phone: {
    display: "flex",
    flexGrow: 0,
    flexShrink: 1,
  },
});

const generateRandomSecret = (length: number) => {
  const bytes = new Uint8Array(length / 2);
  window.crypto.getRandomValues(bytes);
  return Array.from(bytes, decimal => decimal.toString(16).padStart(2, "0")).join("");
};

const ConsentNotification = ({ data }: { data: GetConsentNotificationPageQuery }) => {
  const { projectEnv } = useProjectInfo();
  const canEditConsentNotifications = usePermissions(projectEnv).consentNotification.write;
  const [activateVideo, setActivateVideo] = useBoolean(true);

  const [updateConsentNotification, consentNotificationUpdate] = useMutation(
    UpdateProjectConsentSettingsDocument,
  );

  const [testEndpointUrl, endpointTesting] = useMutation(ProbeConsentNotificationDocument);

  const { Field, setFieldValue, getFieldValue, submitForm, FieldsListener } = useForm({
    endpointUrl: {
      initialValue: data?.projectConsentSettings?.notificationWebhookUrl ?? "",
      strategy: "onChange",
      validate: validateUrl,
    },
    secretKey: {
      initialValue: data?.projectConsentSettings?.secret ?? "",
      strategy: "onSuccessOrBlur",
    },
    logoUrl: {
      initialValue: data?.projectConsentSettings?.appLogoUrl ?? "",
      strategy: "onSuccessOrBlur",
    },
    accentColor: {
      initialValue:
        data?.projectConsentSettings?.appAccentColor.slice(1) ?? invariantColors.black.slice(1),
      strategy: "onSuccessOrBlur",
      validate: validateHexColor,
    },
  });

  const currentEndpointUrl = getFieldValue("endpointUrl");

  const onPressSave = () => {
    return submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { endpointUrl, secretKey, logoUrl, accentColor } = option.get();

          updateConsentNotification({
            input: {
              secret: secretKey,
              appAccentColor: `#${accentColor}`,
              notificationWebhookUrl: endpointUrl,
              ...(logoUrl !== data?.projectConsentSettings?.appLogoUrl && {
                appLogoBase64: logoUrl.split(";base64,")[1],
              }),
            },
          })
            .mapOk(data => data.updateProjectConsentSettings)
            .mapOkToResult(filterRejectionsToResult)
            .tapOk(() =>
              showToast({ variant: "success", title: t("toast.success.consentNotificationSaved") }),
            )
            .tapError(error => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      },
    });
  };

  const onVerifyEndpointUrl = (endpoint: string) => {
    testEndpointUrl({
      input: { endpoint, secret: nullishOrEmptyToUndefined(getFieldValue("secretKey")) },
    })
      .mapOk(data => data.probePartnerConsentNotificationEndpoint)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(({ success }) => {
        if (success) {
          showToast({
            variant: "success",
            title: t("toast.success.consentNotificationUrlVerified"),
          });
        } else {
          showToast({
            variant: "error",
            title: t("toast.error.consentNotificationUrlVerified"),
          });
        }
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <Box direction="row" style={commonStyles.fill}>
      <View style={commonStyles.fill}>
        <LakeScrollView>
          <LakeAlert variant="info" title={t("consentNotification.alert.defaultUserFlow")} />
          <Space height={24} />

          {!currentEndpointUrl && (
            <>
              <LakeAlert variant="warning" title={t("consentNotification.alert.noEndpointUrl")} />
              <Space height={24} />
            </>
          )}

          <Tile
            description={
              <>
                {t("consentNotification.description")}{" "}
                <DocumentationLink to="consentNotification">
                  {t("common.learnMore")}
                </DocumentationLink>
              </>
            }
          >
            <Form style={commonStyles.fill}>
              <Field name="endpointUrl">
                {({ value, valid, error, onChange, onBlur }) => (
                  <LakeLabel
                    label={t("consentNotification.form.endpointUrl")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        style={styles.input}
                        value={value}
                        placeholder={t("consentNotification.form.endpointUrlPlaceholder")}
                        valid={valid}
                        error={error}
                        onChangeText={onChange}
                        onBlur={onBlur}
                      />
                    )}
                    actions={
                      <>
                        <TrackPressable action="Test consent notification">
                          <LakeButton
                            mode="secondary"
                            icon="play-regular"
                            size="small"
                            loading={endpointTesting.isLoading()}
                            disabled={value === "" || isNotNullishOrEmpty(error)}
                            onPress={() => onVerifyEndpointUrl(value)}
                          >
                            {t("webhookForm.test")}
                          </LakeButton>
                        </TrackPressable>

                        <Space width={12} />
                        <CopyTextButton value={value} />
                      </>
                    }
                  />
                )}
              </Field>

              <Field name="secretKey">
                {({ value, onChange, onBlur }) => (
                  <LakeLabel
                    label={t("consentNotification.form.secret")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        style={styles.input}
                        placeholder={t("consentNotification.form.secretPlaceholder")}
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                      />
                    )}
                    actions={
                      <>
                        <LakeTooltip
                          placement="right"
                          content={t("consentNotification.form.generateSecret")}
                        >
                          <TrackPressable action="Generate consent notification secret">
                            <LakeButton
                              mode="secondary"
                              size="small"
                              icon="arrow-counterclockwise-filled"
                              ariaLabel={t("consentNotification.form.generateSecret")}
                              onPress={() => {
                                const secretDefaultLength = 15;
                                onChange(generateRandomSecret(secretDefaultLength));
                              }}
                            />
                          </TrackPressable>
                        </LakeTooltip>

                        <Space width={12} />
                        <CopyTextButton value={value} />
                      </>
                    }
                  />
                )}
              </Field>

              <Field name="accentColor">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("consentNotification.form.color")}
                    render={id => (
                      <ColorInput
                        id={id}
                        placeholder={t("consentNotification.form.colorPlaceholder")}
                        value={value}
                        onValueChange={onChange}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="logoUrl">
                {({ value, onChange }) => (
                  <LakeLabel
                    label={t("consentNotification.form.appLogo")}
                    render={() => (
                      <ImageUploadInput
                        acceptedImageUpload={t("imageUploadInput.accepted", { minHeight: 300 })}
                        imageUri={value}
                        minHeight={300}
                        onImageChange={onChange}
                        buttonText={t("consentNotification.form.uploadFile")}
                        onImageDelete={() => {
                          setFieldValue("logoUrl", "");
                        }}
                        onImageLoadError={() => {
                          setFieldValue("logoUrl", "");
                        }}
                        onImageRejected={() => {
                          showToast({
                            variant: "error",
                            title: t("toast.error.wrongLogoPngFormat"),
                          });
                        }}
                      />
                    )}
                  />
                )}
              </Field>
            </Form>
          </Tile>

          <Space height={24} />

          <Box direction="row" alignItems="start">
            <LakeTooltip
              placement="left"
              content={t("common.action.denied")}
              disabled={canEditConsentNotifications}
            >
              <LakeButton
                color="current"
                size="small"
                onPress={onPressSave}
                loading={consentNotificationUpdate.isLoading()}
                disabled={!canEditConsentNotifications}
              >
                {t("projectSettings.branding.saveButton")}
              </LakeButton>
            </LakeTooltip>
          </Box>

          <Space height={24} />
        </LakeScrollView>
      </View>

      <Space width={32} />

      <View style={styles.phoneContainer}>
        <View style={StyleSheet.absoluteFill}>
          <FieldsListener names={["accentColor", "logoUrl"]}>
            {({ accentColor, logoUrl }) => {
              const isValidColor =
                /^[A-Fa-f0-9]{6}$/.test(accentColor.value) && isNotEmpty(accentColor.value);
              const isValidLogo = isNotEmpty(logoUrl.value);

              return (
                <AppOpeningAnimation
                  playing={activateVideo}
                  accentColor={isValidColor ? `#${accentColor.value}` : invariantColors.black}
                  appLogo={isValidLogo ? logoUrl.value : swanLogo}
                  style={styles.phone}
                />
              );
            }}
          </FieldsListener>

          <Space height={16} />

          <LakeButton
            size="small"
            mode="secondary"
            onPress={setActivateVideo.toggle}
            icon={activateVideo ? "pause-regular" : "play-regular"}
            style={commonStyles.centerSelf}
            ariaLabel={activateVideo ? t("common.pause") : t("common.play")}
          />

          <Space height={24} />
        </View>
      </View>
    </Box>
  );
};

export const ConsentNotificationPage = () => {
  const [data] = useQuery(GetConsentNotificationPageDocument, {});

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => <ConsentNotification data={data} />)
    .exhaustive();
};
