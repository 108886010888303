import { useFocusReset } from "@swan-io/chicane";
import { ClientContext } from "@swan-io/graphql-client";
import { Breadcrumbs, BreadcrumbsRoot, useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { backgroundColor, colors, spacings } from "@swan-io/lake/src/constants/design";
import { nullishOrEmptyToUndefined } from "@swan-io/lake/src/utils/nullish";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { Redirect } from "../components/Redirect";
import { LoggedUserInfoFragment } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { AccessDeniedPage } from "../pages/AccessDeniedPage";
import { BrandingLivePage } from "../pages/BrandingLivePage";
import { BrandingSandboxPage } from "../pages/BrandingSandboxPage";
import { CardProductsPage } from "../pages/CardProductsPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { OnboardingSettingsPage } from "../pages/OnboardingSettingsPage";
import { SettingsPage } from "../pages/SettingsPage";
import { WebBankingPage } from "../pages/WebBankingPage";
import {
  liveExposedInternalByProjectIdClient,
  liveExposedInternalByProjectIdClient__projectMember,
  sandboxExposedInternalByProjectIdClient,
  sandboxExposedInternalByProjectIdClient__projectMember,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { Router, settingsRoots } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { NotificationSettingsPage } from "./NotificationSettingsPage";

const styles = StyleSheet.create({
  base: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "stretch",
  },
  contentContainer: {
    marginHorizontal: "auto",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.gray[50],
    backgroundColor: backgroundColor.default,
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 2560,
    marginHorizontal: "auto",
    flexDirection: "row",
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: spacings[24],
    paddingTop: spacings[16],
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 20,
  },
});

type Props = {
  user: LoggedUserInfoFragment;
};

const SecondLevelCrumb = ({
  route,
}: {
  route: ReturnType<typeof Router.useRoute<(typeof settingsRoots)[number]>>;
}) => {
  const { projectId, projectEnv } = useProjectInfo();

  const siblings = useMemo(
    () => [
      {
        label: t("projectSettings.branding.title"),
        url: Router.SettingsBrandingRoot({ projectId, projectEnv }),
        isMatching: match(route)
          .with(
            { name: "SettingsBrandingRoot" },
            { name: "SettingsBrandingDraft" },
            { name: "SettingsBrandingPending" },
            { name: "SettingsBrandingCurrent" },
            () => true,
          )
          .otherwise(() => false),
      },
      {
        label: t("cardProducts.cards"),
        url: Router.SettingsCardProducts({ projectId, projectEnv }),
        isMatching: route?.name === "SettingsCardProductsArea",
      },
      {
        label: t("projectSettings.onboarding.title"),
        url: Router.SettingsOnboarding({ projectId, projectEnv }),
        isMatching: route?.name === "SettingsOnboarding",
      },
      {
        label: t("projectSettings.webBanking.title"),
        url: Router.SettingsWebBanking({ projectId, projectEnv }),
        isMatching: route?.name === "SettingsWebBanking",
      },
      {
        label: t("projectSettings.notifications.title"),
        url: Router.SettingsNotifications({ projectId, projectEnv }),
        isMatching: route?.name === "SettingsNotifications",
      },
    ],
    [projectEnv, projectId, route],
  );

  useCrumb(
    useMemo(
      () =>
        match(route)
          .with(
            { name: "SettingsBrandingRoot" },
            { name: "SettingsBrandingDraft" },
            { name: "SettingsBrandingPending" },
            { name: "SettingsBrandingCurrent" },
            () => ({
              label: t("projectSettings.branding.title"),
              link: Router.SettingsBrandingRoot({ projectId, projectEnv }),
              siblings,
            }),
          )
          .with({ name: "SettingsCardProductsArea" }, () => ({
            label: t("cardProducts.cards"),
            link: Router.SettingsCardProducts({ projectId, projectEnv }),
            siblings,
          }))
          .with({ name: "SettingsOnboarding" }, () => ({
            label: t("projectSettings.onboarding.title"),
            link: Router.SettingsOnboarding({ projectId, projectEnv }),
            siblings,
          }))
          .with({ name: "SettingsWebBanking" }, () => ({
            label: t("projectSettings.webBanking.title"),
            link: Router.SettingsWebBanking({ projectId, projectEnv }),
            siblings,
          }))
          .with({ name: "SettingsNotifications" }, () => ({
            label: t("projectSettings.notifications.title"),
            link: Router.SettingsNotifications({ projectId, projectEnv }),
            siblings,
          }))
          .otherwise(() => undefined),
      [projectId, route, projectEnv, siblings],
    ),
  );
  return null;
};

export const SettingsArea = ({ user }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const {
    settingsBranding: { read: canViewBranding, write: canEditBranding },
    settingsCard: { read: canViewCard },
    settingsOnboarding: { read: canViewOnboarding },
    settingsWebBanking: { read: canViewWebBanking },
    settingsNotifications: { read: canViewNotificationSettings },
  } = usePermissions(projectEnv);

  const areNotificationSettingsActive = useTgglFlag("dashboardNotificationSettings").getOr(false);

  const route = Router.useRoute(settingsRoots);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("settings.all"),
        link: Router.SettingsRoot({ projectId, projectEnv }),
      },
    ],
    [projectEnv, projectId],
  );

  useFocusReset({ route, containerRef });

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  const exposedInternalClient = match({ projectEnv, shouldUseProjectMemberToken })
    .with({ projectEnv: "live", shouldUseProjectMemberToken: true }, () =>
      liveExposedInternalByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "live" }, () => liveExposedInternalByProjectIdClient(projectId))
    .with({ projectEnv: "sandbox", shouldUseProjectMemberToken: true }, () =>
      sandboxExposedInternalByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "sandbox" }, () => sandboxExposedInternalByProjectIdClient(projectId))
    .exhaustive();

  if (
    [canViewBranding, canViewCard, canViewOnboarding, canViewWebBanking].every(
      item => item === false,
    )
  ) {
    return <AccessDeniedPage />;
  }

  return (
    <ScrollView horizontal={true} style={commonStyles.fill} contentContainerStyle={styles.base}>
      <View style={styles.container}>
        <ScrollView
          role="main"
          style={styles.contentContainer}
          contentContainerStyle={styles.content}
        >
          <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
            <View style={styles.header}>
              <Breadcrumbs />

              {match(route)
                .with({ name: "SettingsWebBanking" }, () => (
                  <InformationTooltip text={t("projectSettings.webBanking.subtitle")} />
                ))
                .otherwise(() => null)}
            </View>

            <Space height={16} />
            <SecondLevelCrumb route={route} />

            {match(route)
              .with({ name: "SettingsRoot" }, () => (
                <LakeScrollView>
                  <SettingsPage />
                  <Space height={24} />
                </LakeScrollView>
              ))
              .with(
                { name: "SettingsBrandingRoot", params: { projectEnv: "live" } },
                ({ params: { projectEnv } }) => (
                  <Redirect to={Router.SettingsBrandingDraft({ projectId, projectEnv })} />
                ),
              )
              .with(
                { name: "SettingsBrandingRoot", params: { projectEnv: "sandbox" } },
                ({ params: { firstVisit } }) =>
                  canViewBranding ? (
                    <BrandingSandboxPage
                      fullName={nullishOrEmptyToUndefined(user.fullName)}
                      firstVisit={firstVisit === "true"}
                    />
                  ) : (
                    <AccessDeniedPage />
                  ),
              )
              .with(
                { name: "SettingsBrandingDraft", params: { projectEnv: "live" } },
                { name: "SettingsBrandingPending", params: { projectEnv: "live" } },
                { name: "SettingsBrandingCurrent", params: { projectEnv: "live" } },
                ({ name }) => {
                  return name === "SettingsBrandingDraft" && !canEditBranding ? (
                    <Redirect
                      to={Router.SettingsBrandingPending({
                        projectId,
                        projectEnv: "live",
                      })}
                    />
                  ) : canViewBranding ? (
                    <BrandingLivePage />
                  ) : (
                    <AccessDeniedPage />
                  );
                },
              )
              .with(
                { name: "SettingsBrandingDraft", params: { projectEnv: "sandbox" } },
                { name: "SettingsBrandingPending", params: { projectEnv: "sandbox" } },
                { name: "SettingsBrandingCurrent", params: { projectEnv: "sandbox" } },
                () => (
                  <Redirect
                    to={Router.SettingsBrandingRoot({ projectId, projectEnv: "sandbox" })}
                  />
                ),
              )
              .with({ name: "SettingsCardProductsArea" }, () =>
                canViewCard ? (
                  <ClientContext.Provider value={exposedInternalClient}>
                    <CardProductsPage user={user} />
                  </ClientContext.Provider>
                ) : (
                  <AccessDeniedPage />
                ),
              )
              .with({ name: "SettingsOnboarding" }, () =>
                canViewOnboarding ? (
                  <ClientContext.Provider value={exposedInternalClient}>
                    <LakeScrollView>
                      <OnboardingSettingsPage />
                      <Space height={24} />
                    </LakeScrollView>
                  </ClientContext.Provider>
                ) : (
                  <AccessDeniedPage />
                ),
              )
              .with({ name: "SettingsWebBanking" }, () =>
                canViewWebBanking ? (
                  <LakeScrollView>
                    <WebBankingPage />
                    <Space height={24} />
                  </LakeScrollView>
                ) : (
                  <AccessDeniedPage />
                ),
              )
              .with({ name: "SettingsNotifications" }, () =>
                areNotificationSettingsActive ? (
                  canViewNotificationSettings ? (
                    <LakeScrollView>
                      <ClientContext.Provider value={exposedInternalClient}>
                        <NotificationSettingsPage />
                      </ClientContext.Provider>

                      <Space height={24} />
                    </LakeScrollView>
                  ) : (
                    <AccessDeniedPage />
                  )
                ) : (
                  <NotFoundPage />
                ),
              )
              .otherwise(() => (
                <NotFoundPage />
              ))}
          </BreadcrumbsRoot>
        </ScrollView>
      </View>
    </ScrollView>
  );
};
