import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { Item, LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { useForm } from "@swan-io/use-form";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  CapitalDepositDocumentStatus,
  DocumentReasonCode,
  SimulateCapitalDepositDocumentStatusDocument,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

const status = ["Pending", "Refused", "Uploaded", "Validated"] as const;

const statusItems: Item<CapitalDepositDocumentStatus>[] = status.map(existingStatus => ({
  name: existingStatus,
  value: existingStatus,
}));

const documentRefusalReasonCodes: Item<DocumentReasonCode>[] = deriveUnion<DocumentReasonCode>({
  CompanyNameMismatch: true,
  ComplianceReason: true,
  DeclaredAmountMismatch: true,
  ExpiredDocument: true,
  InvalidAddress: true,
  InvalidDepositBankName: true,
  InvalidDocument: true,
  InvalidOrMissingData: true,
  MissingAccommodationProviderId: true,
  MissingAccommodationProviderIdLetter: true,
  MissingAccommodationProviderLetter: true,
  MissingCompanyProviderRegisterExtract: true,
  UnacceptableDocument: true,
  ProviderColorIdDocumentRequired: true,
  MissingSignature: true,
  BadDocumentQuality: true,
  FullDocumentRequired: true,
  MissingProviderProofOfAddress: true,
  MissingProviderProofOfAddressAndIdDocument: true,
  PowerOfAttorneyToSwanRequired: true,
  MissingCompanyDomiciliationStatement: true,
  HandwrittenOrCertifiedElectronicSignatureRequired: true,
}).array.map(code => ({
  name: code,
  value: code,
}));

export const UpdateCapitalDepositDocumentStatusPage = () => {
  const { Field, FieldsListener, submitForm, formStatus } = useForm<{
    documentId: string;
    documentStatus: CapitalDepositDocumentStatus;
    documentRefusalReasonCode: DocumentReasonCode;
  }>({
    documentId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    documentStatus: {
      initialValue: "Pending",
      strategy: "onBlur",
    },
    documentRefusalReasonCode: {
      initialValue: "InvalidDocument",
      strategy: "onBlur",
    },
  });

  const [simulate, simulation] = useMutation(SimulateCapitalDepositDocumentStatusDocument);

  const result = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with({ __typename: "SimulateCapitalDepositDocumentStatusSuccessPayload" }, ({ id }) =>
        Result.Ok([{ key: "documentId", value: id }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const onSubmit = () =>
    submitForm({
      onSuccess: ({ documentId, documentStatus, documentRefusalReasonCode }) =>
        Option.allFromDict({ documentId, documentStatus })
          .map(input =>
            simulate({
              input: {
                ...input,
                documentRefusalReasonCode: documentRefusalReasonCode.toUndefined(),
              },
            }),
          )
          .toUndefined(),
    });

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.capitalDeposit.updateDocumentStatusDescription")}{" "}
            <DocumentationLink to="simulatorCapitalDeposit">
              {t("common.learnMore")}
            </DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="documentId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.capitalDepositDocumentId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.capitalDepositIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="documentStatus">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.capitalDepositDocumentStatus")}
                render={id => (
                  <LakeSelect id={id} items={statusItems} value={value} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <FieldsListener names={["documentStatus"]}>
            {({ documentStatus }) =>
              documentStatus.value === "Refused" ? (
                <Field name="documentRefusalReasonCode">
                  {({ value, onChange }) => (
                    <LakeLabel
                      label={t("simulatorForm.capitalDepositDocumentRefusalReasonCode")}
                      render={id => (
                        <LakeSelect
                          id={id}
                          items={documentRefusalReasonCodes}
                          value={value}
                          onValueChange={onChange}
                        />
                      )}
                    />
                  )}
                </Field>
              ) : null
            }
          </FieldsListener>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit physical card delivery form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          results={result}
          fields={[
            {
              key: "documentId",
              label: t("simulatorForm.capitalDepositDocumentId"),
              placeholder: "-",
            },
          ]}
        />
      </Box>
    </Form>
  );
};
