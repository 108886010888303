import { AsyncData, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeRadio } from "@swan-io/lake/src/components/LakeRadio";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, negativeSpacings, spacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { countries as countryList } from "@swan-io/shared-business/src/constants/countries";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  ActiveSandboxUserDocument,
  EndorseSandboxUserDocument,
  SandboxUserFragment,
  SandboxUsersDocument,
} from "../graphql/sandbox-partner-admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  radio: {
    justifyContent: "center",
    flexGrow: 1,
    paddingHorizontal: spacings[16],
  },
});

type Edge = SandboxUserFragment;

type ExtraInfo = {
  projectId: string;
  countries: Record<string, string | undefined>;
  logAs: (id: string) => void;
  activeUserId?: string;
  isEndorsementLoading: boolean;
};

const keyExtractor = ({ id }: Edge) => id;

const getRowLink = ({ item: { id }, extraInfo: { projectId } }: LinkConfig<Edge, ExtraInfo>) => (
  <Link to={Router.SandboxDevelopersUsersEdit({ projectId, userId: id })} />
);

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 90,
    id: "logAs",
    title: t("sandboxUsers.table.logAs"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { id }, extraInfo: { logAs, activeUserId, isEndorsementLoading } }) => {
      return (
        <TrackPressable action="Log as sandbox user">
          <Pressable
            role="radio"
            aria-checked={activeUserId === id}
            style={styles.radio}
            disabled={isEndorsementLoading}
            onPress={event => {
              event.preventDefault();
              logAs(id);
            }}
          >
            <LakeRadio value={activeUserId === id} disabled={isEndorsementLoading} />
          </Pressable>
        </TrackPressable>
      );
    },
  },
  {
    width: 200,
    id: "name",
    title: t("sandboxUsers.table.userName"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { id, firstName, lastName }, extraInfo: { activeUserId } }) => (
      <TextCell
        variant="medium"
        color={id === activeUserId ? colors.current.primary : undefined}
        text={[firstName, lastName].filter(Boolean).join(" ")}
      />
    ),
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 250,
    id: "id",
    title: t("sandboxUsers.table.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { id } }) => {
      return (
        <CopyableTextCell
          text={id}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      );
    },
  },
  {
    width: 100,
    id: "expert",
    title: t("sandboxUsers.table.identificationLevels.expert"),
    renderTitle: ({ title }) => <HeaderCell text={title} align="center" />,
    renderCell: ({ item: { identificationLevels } }) => {
      return (
        <Cell align="center">
          {identificationLevels?.expert === true ? (
            <Tag color="positive">{t("common.true")}</Tag>
          ) : (
            <Tag color="gray">{t("common.false")}</Tag>
          )}
        </Cell>
      );
    },
  },
  {
    width: 100,
    id: "qes",
    title: t("sandboxUsers.table.identificationLevels.qes"),
    renderTitle: ({ title }) => <HeaderCell text={title} align="center" />,
    renderCell: ({ item: { identificationLevels } }) => {
      return (
        <Cell align="center">
          {identificationLevels?.QES === true ? (
            <Tag color="positive">{t("common.true")}</Tag>
          ) : (
            <Tag color="gray">{t("common.false")}</Tag>
          )}
        </Cell>
      );
    },
  },
  {
    width: 100,
    id: "pvid",
    title: t("sandboxUsers.table.identificationLevels.pvid"),
    renderTitle: ({ title }) => <HeaderCell text={title} align="center" />,
    renderCell: ({ item: { identificationLevels } }) => {
      return (
        <Cell align="center">
          {identificationLevels?.PVID === true ? (
            <Tag color="positive">{t("common.true")}</Tag>
          ) : (
            <Tag color="gray">{t("common.false")}</Tag>
          )}
        </Cell>
      );
    },
  },
  {
    width: 200,
    id: "phone",
    title: t("sandboxUsers.table.phoneNumber"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { mobilePhoneNumber } }) =>
      isNotNullish(mobilePhoneNumber) ? <TextCell text={mobilePhoneNumber} /> : null,
  },
  {
    width: 200,
    id: "birthdate",
    title: t("sandboxUsers.table.birthdate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { birthDate } }) =>
      isNotNullish(birthDate) ? (
        <TextCell text={dayjs(birthDate).format(locale.dateFormat)} />
      ) : null,
  },
  {
    width: 200,
    id: "nationality",
    title: t("sandboxUsers.table.nationality"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { nationalityCCA3 }, extraInfo: { countries } }) => {
      const country = countries[nationalityCCA3 ?? ""];
      return isNotNullish(country) ? <TextCell text={country} /> : nationalityCCA3;
    },
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 64,
    id: "actions",
    title: "",
    renderTitle: () => null,
    renderCell: ({ isHovered }) => (
      <Cell align="right">
        <Icon
          name="chevron-right-filled"
          color={isHovered ? colors.gray[700] : colors.gray[200]}
          size={16}
        />
      </Cell>
    ),
  },
];

const PER_PAGE = 20;

export const SandboxUsersPage = () => {
  const { projectId } = useProjectInfo();

  const [data, { isLoading, reload, setVariables }] = useQuery(SandboxUsersDocument, {
    first: PER_PAGE,
  });

  // The `sandboxUser` query gives us the active one, we use this query as a source of
  // truth on this information so that after calling `endorseSandboxUser`, the list
  // only has *one* active sandbox user (if we only rely on the mutation response, the
  // previously active one isn't invalidated from the cache, meaning we'd have two active ones)
  const [activeSandboxUser, { reload: reloadActiveSandboxUser }] = useQuery(
    ActiveSandboxUserDocument,
    {},
  );

  const [endorseSandboxUser, userEndorsement] = useMutation(EndorseSandboxUserDocument);

  const openNew = useCallback(() => {
    Router.push("DevelopersUsersRoot", { projectId, projectEnv: "sandbox", new: "" });
  }, [projectId]);

  const logAs = useCallback(
    (userId: string) => {
      endorseSandboxUser({ input: { id: userId } }).onResolve(() => {
        reloadActiveSandboxUser();
      });
    },
    [endorseSandboxUser, reloadActiveSandboxUser],
  );

  const countries = useMemo(
    () =>
      countryList.reduce<Record<string, string>>((acc, country) => {
        acc[country.cca3] = country.name;
        return acc;
      }, {}),
    [],
  );

  const activeUserId = activeSandboxUser
    .toOption()
    .flatMap(result => result.toOption())
    .map(data => data.sandboxUser.id)
    .toUndefined();

  const isEndorsementLoading = userEndorsement.isLoading() || activeSandboxUser.isLoading();

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectId,
      countries,
      logAs,
      activeUserId,
      isEndorsementLoading,
    };
  }, [projectId, countries, logAs, activeUserId, isEndorsementLoading]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <View style={commonStyles.fill}>
      <Box direction="row" justifyContent="spaceBetween">
        <Box direction="row">
          <TrackPressable action="New sandbox user">
            <LakeButton size="small" icon="add-circle-filled" onPress={openNew} color="current">
              {t("common.new")}
            </LakeButton>
          </TrackPressable>

          <Space width={8} />

          <TrackPressable action="Refresh sandbox users list">
            <LakeButton
              size="small"
              mode="secondary"
              icon="arrow-counterclockwise-filled"
              loading={isRefreshing}
              onPress={() => {
                setIsRefreshing(true);
                reload().tap(() => setIsRefreshing(false));
              }}
              color="gray"
              ariaLabel={t("common.refresh")}
            />
          </TrackPressable>
        </Box>
      </Box>

      <Space height={40} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.sandboxUsers}>
            {sandboxUsers => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={sandboxUsers?.edges.map(item => item.node) ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                columns={columns}
                loading={{
                  isLoading,
                  count: PER_PAGE,
                }}
                stickedToStartColumns={stickedToStartColumns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                rowHeight={48}
                getRowLink={getRowLink}
                onEndReached={() => {
                  if (sandboxUsers?.pageInfo.hasNextPage === true) {
                    setVariables({ after: sandboxUsers.pageInfo.endCursor ?? undefined });
                  }
                }}
                renderEmptyList={() => (
                  <EmptyView icon="lake-inbox-empty" title={t("membersTable.noResultFound")} />
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </View>
  );
};
