import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  ColumnTitleConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { identity } from "@swan-io/lake/src/utils/function";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  AccountHolderOrderByFieldInput,
  AccountHolderOrderByInput,
  AccountHolderType,
  ExportOnboardingDataDocument,
  GetOnboardingsPageDocument,
  GetOnboardingsPageQuery,
  OnboardingStatus,
  OrderByDirection,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, locale, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

type Edge = GetEdge<GetOnboardingsPageQuery["onboardings"]>;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountHolderOrderByInput) => void;
  sortBy?: AccountHolderOrderByInput;
};

type Props = {
  params: RouteParams<"OnboardingRoot">;
  membershipEmail: string | undefined;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link to={Router.OnboardingDetailRoot({ projectId, projectEnv, onboardingId: id })} />
);

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 150,
    id: "type",
    title: t("onboarding.list.type"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          info: { type },
        },
      },
    }) => {
      const isIndividual = type === "Individual";

      return (
        <Cell>
          <Tag
            color={isIndividual ? "darkPink" : "shakespear"}
            icon={isIndividual ? "person-regular" : "building-regular"}
          >
            {isIndividual ? t("onboarding.general.individual") : t("onboarding.general.company")}
          </Tag>
        </Cell>
      );
    },
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 250,
    id: "email",
    title: t("onboarding.list.email"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { email },
      },
    }) => <TextCell variant="medium" text={email ?? ""} />,
  },
  {
    width: 400,
    id: "id",
    title: t("onboarding.list.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 200,
    id: "accountHolder",
    title: t("onboarding.list.accountHolder"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { accountHolder },
      },
      extraInfo: { projectEnv, projectId },
    }) =>
      isNotNullish(accountHolder) ? (
        <LinkCell
          onPress={() => {
            Router.push("HoldersDetailRoot", {
              projectId,
              projectEnv,
              accountHolderId: accountHolder.id,
            });
          }}
        >
          {accountHolder.info.name}
        </LinkCell>
      ) : null,
  },
  {
    width: 200,
    id: "createdAt",
    title: t("onboarding.list.createdAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort onboadings list by creation date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("onboarding.list.updatedAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort onboadings list by update date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 200,
    id: "finalizedAt",
    title: t("onboarding.list.finalizedAt"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { finalizedAt },
      },
    }) =>
      isNotNullish(finalizedAt) && (
        <TextCell text={dayjs(finalizedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
      ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 180,
    id: "status",
    title: t("onboarding.list.status"),
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: ({
      item: {
        node: { statusInfo },
      },
    }) => (
      <Cell align="right">
        <Tag
          color={
            statusInfo.status === "Finalized"
              ? "positive"
              : statusInfo.status === "Valid"
                ? "shakespear"
                : "warning"
          }
        >
          {statusInfo.status === "Finalized"
            ? t("onboarding.general.status.finalized")
            : statusInfo.status === "Valid"
              ? t("onboarding.general.status.valid")
              : t("onboarding.general.status.invalid")}
        </Tag>
      </Cell>
    ),
  },

  {
    width: 48,
    id: "actions",
    title: "",
    renderTitle: () => null,
    renderCell: ({ isHovered }) => (
      <Cell align="right">
        <Icon
          name="chevron-right-filled"
          color={isHovered ? colors.gray[700] : colors.gray[200]}
          size={16}
        />
      </Cell>
    ),
  },
];

const PER_PAGE = 20;

const typeList = ["Company", "Individual"] as const;
const statusList = ["Finalized", "Invalid", "Valid"] as const;

export const OnboardingListPage = ({ params, membershipEmail }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [exportDataVisible, { open: openExportDataModal, close: closeExportDataModal }] =
    useDisclosure(false);

  const [exportOnboardingData, onboardingDataExported] = useMutation(ExportOnboardingDataDocument);

  const onSubmit = () => {
    if (isNotNullish(membershipEmail)) {
      exportOnboardingData({
        input: {
          email: membershipEmail,
          filters,
        },
      })
        .mapOkToResult(data => Option.fromNullable(data.exportOnboardingData).toResult("No data"))
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => {
          closeExportDataModal();
          showToast({ variant: "success", title: t("acountStatements.dataExported") });
        })
        .tapError(error => {
          showToast({ variant: "error", title: translateError(error), error });
        });
    }
  };

  const filters: OnboardingFilters = useMemo(() => {
    return {
      types: isNotNullish(params.types)
        ? Array.filterMap(params.types, item =>
            match(item)
              .with(...typeList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      status: isNotNullish(params.status)
        ? Array.filterMap(params.status, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [params.types, params.status]);

  const sortBy: AccountHolderOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<AccountHolderOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetOnboardingsPageDocument, {
    first: 20,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("OnboardingRoot", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
    };
  }, [projectEnv, projectId, sortBy, params]);

  const onboardings = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ onboardings }) => onboardings);

  const totalCount = onboardings.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <OnboardingFiltersForm
        membershipEmail={membershipEmail}
        openExportDataModal={openExportDataModal}
        filters={filters}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("OnboardingRoot", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("OnboardingRoot", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.onboardings}>
            {onboardings => (
              <>
                <VirtualizedList
                  variant="default"
                  marginHorizontal={negativeSpacings[24]}
                  data={onboardings.edges}
                  keyExtractor={keyExtractor}
                  extraInfo={extraInfo}
                  columns={columns}
                  stickedToStartColumns={stickedToStartColumns}
                  stickedToEndColumns={stickedToEndColumns}
                  headerHeight={48}
                  rowHeight={48}
                  getRowLink={getRowLink}
                  onEndReached={() => {
                    if (onboardings.pageInfo.hasNextPage === true) {
                      setVariables({ after: onboardings.pageInfo.endCursor ?? undefined });
                    }
                  }}
                  loading={{
                    isLoading,
                    count: PER_PAGE,
                  }}
                  renderEmptyList={() =>
                    hasSearchOrFilters ? (
                      <EmptyView
                        icon="clipboard-search-regular"
                        title={t("common.list.noResults")}
                        subtitle={t("common.list.noResultsSuggestion")}
                      />
                    ) : (
                      <EmptyView
                        icon="lake-inbox-empty"
                        title={t("onboarding.list.empty.title")}
                        subtitle={t("onboarding.list.empty.subtitle")}
                      />
                    )
                  }
                />

                <LakeModal
                  icon="arrow-download-filled"
                  visible={exportDataVisible}
                  onPressClose={closeExportDataModal}
                  title={t("dataExport.exportData")}
                >
                  <LakeText color={colors.gray[900]}>
                    {onboardings.totalCount > 100_000
                      ? t("dataExport.exportData.largeExportDescription")
                      : t("dataExport.exportData.smallExportDescription")}
                  </LakeText>

                  <LakeButtonGroup paddingBottom={0}>
                    <TrackPressable action="Export user data">
                      <LakeButton
                        color="current"
                        grow={true}
                        onPress={onSubmit}
                        loading={onboardingDataExported.isLoading()}
                      >
                        {t("dataExport.exportData.confirm")}
                      </LakeButton>
                    </TrackPressable>
                  </LakeButtonGroup>
                </LakeModal>
              </>
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const typeFilter: FilterCheckboxDef<AccountHolderType> = {
  type: "checkbox",
  label: t("cards.list.type"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Individual", label: t("onboarding.general.individual") },
    { value: "Company", label: t("onboarding.general.company") },
  ],
};

const statusFilter: FilterCheckboxDef<OnboardingStatus> = {
  type: "checkbox",
  label: t("cards.list.status"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Finalized", label: t("onboarding.general.finalized") },
    { value: "Invalid", label: t("onboarding.general.invalid") },
    { value: "Valid", label: t("onboarding.general.valid") },
  ],
};

const filtersDefinition = {
  types: typeFilter,
  status: statusFilter,
};

type OnboardingFilters = FiltersState<typeof filtersDefinition>;

type OnboardingFiltersFormProps = {
  filters: OnboardingFilters;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: OnboardingFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
  openExportDataModal: () => void;
  membershipEmail: string | undefined;
};

const OnboardingFiltersForm = ({
  filters,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
  openExportDataModal,
  membershipEmail,
}: OnboardingFiltersFormProps) => {
  const availableFilters: { name: keyof OnboardingFilters; label: string }[] = useMemo(
    () => [
      {
        name: "types",
        label: t("onboarding.general.filters.type"),
      },
      {
        name: "status",
        label: t("onboarding.general.filters.status"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="spaceBetween">
        <Box direction="row">
          <FilterChooser
            filters={filters}
            openFilters={openFilters}
            label={t("common.filters")}
            onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
            availableFilters={availableFilters}
          />

          <Space width={16} />

          {isNotNullish(membershipEmail) && (
            <>
              <LakeTooltip placement="center" content={t("dataExport.exportData")}>
                <TrackPressable action="Export onboarding data">
                  <LakeButton
                    mode="secondary"
                    size="small"
                    onPress={openExportDataModal}
                    icon="arrow-download-filled"
                    ariaLabel={t("dataExport.exportData")}
                  />
                </TrackPressable>
              </LakeTooltip>

              <Space width={16} />
            </>
          )}

          <TrackPressable action="Refresh onboardings list">
            <LakeButton
              ariaLabel={t("common.refresh")}
              mode="secondary"
              size="small"
              icon="arrow-counterclockwise-filled"
              loading={isRefreshing}
              onPress={() => {
                setIsRefreshing(true);
                onRefresh().tap(() => setIsRefreshing(false));
              }}
            />
          </TrackPressable>
        </Box>

        <Fill minWidth={16} />

        <Box direction="row">
          <LakeSearchField
            placeholder={t("common.search")}
            initialValue={search ?? ""}
            onChangeText={text => onChangeSearch(emptyToUndefined(text))}
            renderEnd={() =>
              totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
            }
          />
        </Box>
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
