import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { Link } from "@swan-io/lake/src/components/Link";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";

const styles = StyleSheet.create({
  item: {
    display: "flex",
    minHeight: 200,
  },
});

export const SettingsPage = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const areNotificationSettingsActive = useTgglFlag("dashboardNotificationSettings").getOr(false);

  const links: { link: string; title: string; description: string; icon: IconName }[] =
    useMemo(() => {
      return [
        {
          link: Router.SettingsBrandingRoot({ projectId, projectEnv }),
          title: t("menu.settingsBranding"),
          description: t("menu.settings.branding.description"),
          icon: "paint-brush-regular",
        },
        {
          link: Router.SettingsCardProducts({ projectId, projectEnv }),
          title: t("menu.cards"),
          description: t("menu.settings.cards.description"),
          icon: "payment-regular",
        },
        {
          link: Router.SettingsWebBanking({ projectId, projectEnv }),
          title: t("menu.settingsWebBanking"),
          description: t("menu.settings.webBanking.description"),
          icon: "building-bank-regular",
        },
        {
          link: Router.SettingsOnboarding({ projectId, projectEnv }),
          title: t("menu.onboarding"),
          description: t("menu.settings.onboarding.description"),
          icon: "form-new-regular",
        },
        ...(areNotificationSettingsActive
          ? [
              {
                link: Router.SettingsNotifications({ projectId, projectEnv }),
                title: t("menu.notifications"),
                description: t("menu.settings.notifications.description"),
                icon: "alert-regular" as const,
              },
            ]
          : []),
      ];
    }, [projectId, projectEnv, areNotificationSettingsActive]);

  return (
    <TileGrid>
      {links.map(item => {
        return (
          <Link to={item.link} style={styles.item} key={item.link}>
            {({ hovered }) => (
              <Tile
                flexGrow={1}
                title={item.title}
                description={item.description}
                numberOfLines={3}
                hovered={hovered}
                icon={<Icon name={item.icon} size={20} color={colors.current.primary} />}
              />
            )}
          </Link>
        );
      })}
    </TileGrid>
  );
};
