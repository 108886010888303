import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { ReactNode, createContext, useContext, useMemo } from "react";
import { UserPermissionsFragment } from "../graphql/admin";

type ReadWrite = {
  read: boolean;
  write: boolean;
};

const _rw = (read: boolean | undefined, write: boolean | undefined): ReadWrite => ({
  read: Boolean(read),
  write: Boolean(write),
});

const remapPermissionsByEnv = (
  _: Partial<UserPermissionsFragment>,
  rw: (read: boolean | undefined, write: boolean | undefined) => ReadWrite,
) => {
  const all = {
    projectStatusManagement: rw(_.projectStatusManagement_write, _.projectStatusManagement_write), // doesn't follow the read / write pattern
    teamManagement: rw(_.teamManagement_read, _.teamManagement_write),
  } satisfies Record<string, ReadWrite>;

  const sandbox = {
    ...all,

    consentNotification: rw(
      _.sandbox_consentNotification_read,
      _.sandbox_consentNotification_write,
    ),
    dataAccount: rw(_.sandbox_dataAccount_read, _.sandbox_dataAccount_write),
    dataAccountHolder: rw(_.sandbox_dataAccountHolder_read, _.sandbox_dataAccountHolder_write),
    dataAccountMembership: rw(
      _.sandbox_dataAccountMembership_read,
      _.sandbox_dataAccountMembership_write,
    ),
    dataCapitalDeposit: rw(_.sandbox_dataCapitalDeposit_read, _.sandbox_dataCapitalDeposit_write),
    dataCard: rw(_.sandbox_dataCard_read, _.sandbox_dataCard_write),
    dataOnboarding: rw(_.sandbox_dataOnboarding_read, _.sandbox_dataOnboarding_write),
    dataTransaction: rw(_.sandbox_dataTransaction_read, _.sandbox_dataTransaction_write),
    dataUser: rw(_.sandbox_dataUser_read, _.sandbox_dataUser_write),
    eventSimulator: rw(_.sandbox_eventSimulator_read, _.sandbox_eventSimulator_write),
    insights: rw(_.sandbox_insights_read, _.sandbox_insights_write),
    oAuthSettings: rw(_.sandbox_oAuthSettings_read, _.sandbox_oAuthSettings_write),
    paymentControl: rw(_.sandbox_paymentControl_read, _.sandbox_paymentControl_write),
    sandboxUser: rw(_.sandbox_sandboxUser, _.sandbox_sandboxUser), // doesn't follow the read / write pattern
    serverConsent: rw(_.sandbox_serverConsent_read, _.sandbox_serverConsent_write),
    settingsBranding: rw(_.sandbox_settingsBranding_read, _.sandbox_settingsBranding_write),
    settingsCard: rw(_.sandbox_settingsCard_read, _.sandbox_settingsCard_write),
    settingsNotifications: rw(
      _.sandbox_settingsNotifications_read,
      _.sandbox_settingsNotifications_write,
    ),
    settingsOnboarding: rw(_.sandbox_settingsOnboarding_read, _.sandbox_settingsOnboarding_write),
    settingsWebBanking: rw(_.sandbox_settingsWebBanking_read, _.sandbox_settingsWebBanking_write),
    webhook: rw(_.sandbox_webhook_read, _.sandbox_webhook_write),
  } satisfies Record<string, ReadWrite>;

  const live: typeof sandbox = {
    ...all,

    consentNotification: rw(_.live_consentNotification_read, _.live_consentNotification_write),
    dataAccount: rw(_.live_dataAccount_read, _.live_dataAccount_write),
    dataAccountHolder: rw(_.live_dataAccountHolder_read, _.live_dataAccountHolder_write),
    dataAccountMembership: rw(
      _.live_dataAccountMembership_read,
      _.live_dataAccountMembership_write,
    ),
    dataCapitalDeposit: rw(_.live_dataCapitalDeposit_read, _.live_dataCapitalDeposit_write),
    dataCard: rw(_.live_dataCard_read, _.live_dataCard_write),
    dataOnboarding: rw(_.live_dataOnboarding_read, _.live_dataOnboarding_write),
    dataTransaction: rw(_.live_dataTransaction_read, _.live_dataTransaction_write),
    dataUser: rw(_.live_dataUser_read, _.live_dataUser_write),
    insights: rw(_.live_insights_read, _.live_insights_write),
    oAuthSettings: rw(_.live_oAuthSettings_read, _.live_oAuthSettings_write),
    paymentControl: rw(_.live_paymentControl_read, _.live_paymentControl_write),
    sandboxUser: rw(false, false), // doesn't exists in live
    eventSimulator: rw(false, false), // doesn't exists in live
    serverConsent: rw(_.live_serverConsent_read, _.live_serverConsent_write),
    settingsBranding: rw(_.live_settingsBranding_read, _.live_settingsBranding_write),
    settingsCard: rw(_.live_settingsCard_read, _.live_settingsCard_write),
    settingsNotifications: rw(
      _.live_settingsNotifications_read,
      _.live_settingsNotifications_write,
    ),
    settingsOnboarding: rw(_.live_settingsOnboarding_read, _.live_settingsOnboarding_write),
    settingsWebBanking: rw(_.live_settingsWebBanking_read, _.live_settingsWebBanking_write),
    webhook: rw(_.live_webhook_read, _.live_webhook_write),
  };

  return { live, sandbox };
};

type Permissions = ReturnType<typeof remapPermissionsByEnv>;
const PermissionsContext = createContext<Permissions | undefined>(undefined);

type Props = {
  children: ReactNode;
  value: Partial<UserPermissionsFragment> | undefined;
};

const EMPTY: Props["value"] = {};

export const PermissionsProvider = ({ children, value = EMPTY }: Props) => {
  return (
    <PermissionsContext.Provider value={useMemo(() => remapPermissionsByEnv(value, _rw), [value])}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = <T extends keyof Permissions>(env: T): Permissions[T] => {
  const permissions = useContext(PermissionsContext);

  if (isNullish(permissions)) {
    throw new Error("Missing <PermissionsProvider />");
  }

  return permissions[env];
};
