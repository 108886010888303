import {
  BalanceCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnConfig,
  ColumnTitleConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { P, match } from "ts-pattern";
import { AccountListFragment, AccountOrderByInput } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { Router } from "../utils/routes";
import { TrackPressable } from "./TrackPressable";

export type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountOrderByInput) => void;
  sortBy?: AccountOrderByInput;
  reexecuteQuery: () => void;
};

type Edge = GetEdge<AccountListFragment>;

type Props = {
  accounts: AccountListFragment["edges"];
  onEndReached: () => void;
  hasSearchOrFilters?: boolean;
  perPage: number;
  isLoading: boolean;
  extraInfo: ExtraInfo;
  getRowLink?: (config: LinkConfig<Edge, ExtraInfo>) => ReactElement | undefined;
  emptyListTitle: string;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 180,
    id: "number",
    title: t("account.number"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { number },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <TextCell variant="medium" text={number} />,
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 150,
    id: "name",
    title: t("account.name"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { name },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <TextCell text={name} />,
  },
  {
    width: 240,
    id: "holder",
    title: t("account.accountHolder"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          holder: {
            id: accountHolderId,
            info: { name },
          },
        },
      },
      extraInfo: { projectEnv, projectId },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <LinkCell
        onPress={() => Router.push("HoldersDetailRoot", { projectId, projectEnv, accountHolderId })}
      >
        {name}
      </LinkCell>
    ),
  },
  {
    width: 240,
    id: "id",
    title: t("account.id"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 150,
    id: "paymentLevel",
    title: t("account.paymentLevel"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentLevel },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell>
        {match(paymentLevel)
          .with("Limited", () => (
            <Tag color="warning">{t("accounts.filters.paymentLevels.limited")}</Tag>
          ))
          .with("Unlimited", () => (
            <Tag color="positive">{t("accounts.filters.paymentLevels.unlimited")}</Tag>
          ))
          .with(P.nullish, () => null)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 170,
    id: "accountType",
    title: t("account.accountType"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentAccountType },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell>
        {match(paymentAccountType)
          .with("EMoney", () => (
            <Tag color="shakespear">{t("accounts.paymentAccountType.eMoney")}</Tag>
          ))
          .with("PaymentService", () => (
            <Tag color="positive">{t("accounts.paymentAccountType.PaymentService")}</Tag>
          ))
          .with(P.nullish, () => null)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 150,
    id: "status",
    title: t("account.status"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          statusInfo: { status },
        },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell>
        {match(status)
          .with("Opened", () => <Tag color="positive">{t("account.status.opened")}</Tag>)
          .with("Suspended", () => <Tag color="negative">{t("account.status.suspended")}</Tag>)
          .with("Closing", () => <Tag color="warning">{t("account.status.closing")}</Tag>)
          .with("Closed", () => <Tag color="gray">{t("account.status.closed")}</Tag>)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 340,
    id: "iban",
    title: t("account.iban"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { IBAN },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(IBAN) ? (
        <CopyableTextCell
          text={printMaskedIbanFormat(IBAN)}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      ) : null,
  },
  {
    width: 200,
    id: "createdAt",
    title: t("account.createdAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort accounts by creation date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("account.updatedAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort accounts by update date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 180,
    id: "availableBalance",
    title: t("account.availableBalance"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <HeaderCell text={title} align="right" />
    ),
    renderCell: ({
      item: {
        node: { balances },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(balances) ? (
        <BalanceCell
          value={Number(balances.available.value)}
          currency={balances.available.currency}
          formatCurrency={formatCurrency}
        />
      ) : null,
  },
  {
    width: 180,
    id: "bookedBalance",
    title: t("account.bookedBalance"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <HeaderCell text={title} align="right" />
    ),
    renderCell: ({
      item: {
        node: { balances },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(balances) ? (
        <BalanceCell
          value={Number(balances.booked.value)}
          currency={balances.booked.currency}
          formatCurrency={formatCurrency}
        />
      ) : null,
  },
  {
    width: 48,
    id: "actions",
    title: t("common.table.actions"),
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell align="right">
        <Icon
          name="chevron-right-filled"
          color={isHovered ? colors.gray[700] : colors.gray[200]}
          size={16}
        />
      </Cell>
    ),
  },
];

export const AccountList = ({
  accounts,
  onEndReached,
  isLoading,
  perPage,
  hasSearchOrFilters = false,
  extraInfo,
  getRowLink,
  emptyListTitle,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      marginHorizontal={negativeSpacings[24]}
      extraInfo={extraInfo}
      keyExtractor={keyExtractor}
      data={accounts}
      stickedToStartColumns={stickedToStartColumns}
      columns={columns}
      stickedToEndColumns={stickedToEndColumns}
      onEndReached={onEndReached}
      headerHeight={48}
      rowHeight={48}
      getRowLink={getRowLink}
      loading={{
        isLoading,
        count: perPage,
      }}
      renderEmptyList={() =>
        hasSearchOrFilters ? (
          <EmptyView
            icon="clipboard-search-regular"
            title={t("common.list.noResults")}
            subtitle={t("common.list.noResultsSuggestion")}
          />
        ) : (
          <EmptyView
            icon="lake-inbox-empty"
            title={emptyListTitle}
            subtitle={t("accounts.list.empty.subtitle")}
          />
        )
      }
    />
  );
};
