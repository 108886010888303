import { AsyncData, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Switch } from "@swan-io/lake/src/components/Switch";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { StyleSheet } from "react-native";
import { match, P } from "ts-pattern";
import {
  NotificationSettingsDocument,
  UpdateNotificationSettingsDocument,
  type UpdateNotificationSettingsInput,
} from "../graphql/exposed-internal";
import { t } from "../utils/i18n";
import { ErrorView } from "./ErrorView";

const styles = StyleSheet.create({
  lineDescription: {
    fontStyle: "italic",
  },
});

type LineProps = {
  isUpdating: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  name: string;
  description?: string;
};

const NotificationSettingsLine = ({
  isUpdating,
  value,
  name,
  description,
  onChange,
}: LineProps) => {
  return (
    <Box direction="row" alignItems="center">
      <Switch disabled={isUpdating} value={value} onValueChange={onChange} />
      <Space width={12} />

      <Box grow={1} shrink={1}>
        <LakeText color={colors.gray[900]} variant="smallRegular">
          {name}
        </LakeText>

        {description != null ? (
          <LakeText color={colors.gray[500]} variant="smallRegular" style={styles.lineDescription}>
            {description}
          </LakeText>
        ) : null}
      </Box>

      <Space width={12} />

      <LakeTooltip content={t("projectSettings.notifications.tooltip.sms")}>
        <Tag color={value ? "positive" : "gray"} icon="phone-regular" />
      </LakeTooltip>
    </Box>
  );
};

export const NotificationSettingsPage = () => {
  const [data, { isLoading, refresh }] = useQuery(NotificationSettingsDocument, {});
  const [updateNotificationSettings, notificationSettingsUpdate] = useMutation(
    UpdateNotificationSettingsDocument,
    {},
  );

  const updateSettings = (input: UpdateNotificationSettingsInput) => {
    updateNotificationSettings({ input })
      .mapOkToResult(filterRejectionsToResult)
      .tapError(error => showToast({ variant: "error", title: translateError(error), error }))
      .tapOk(() => {
        refresh();
      });
  };

  // We need to wait for both because there's no cachable key given the shape of the API
  const isUpdating = isLoading || notificationSettingsUpdate.isLoading();

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <TileGridPlaceholder withTabs={false} numberOfItems={1} />
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ notificationSettings }) => (
      <TileGrid>
        <Tile
          icon={<Icon size={28} name="payment-regular" color={colors.current.primary} />}
          title={t("projectSettings.notifications.rejectedCardTransaction")}
          description={t("projectSettings.notifications.rejectedCardTransaction.description")}
        >
          <NotificationSettingsLine
            isUpdating={isUpdating}
            value={notificationSettings?.cardInsufficientFunds?.sms ?? false}
            onChange={sms => updateSettings({ cardInsufficientFunds: { sms } })}
            name={t("projectSettings.notifications.rejectedCardTransaction.insufficientFunds")}
            description={t(
              "projectSettings.notifications.rejectedCardTransaction.insufficientFunds.description",
            )}
          />

          <Separator space={12} />

          <NotificationSettingsLine
            isUpdating={isUpdating}
            value={notificationSettings?.cardSpendingLimitReached?.sms ?? false}
            onChange={sms => updateSettings({ cardSpendingLimitReached: { sms } })}
            name={t(
              "projectSettings.notifications.rejectedCardTransaction.cardSpendingLimitReached",
            )}
          />

          <Separator space={12} />

          <NotificationSettingsLine
            isUpdating={isUpdating}
            value={notificationSettings?.cardPermanentlyBlocked?.sms ?? false}
            onChange={sms => updateSettings({ cardPermanentlyBlocked: { sms } })}
            name={t("projectSettings.notifications.rejectedCardTransaction.cardPermanentlyBlocked")}
          />
        </Tile>
      </TileGrid>
    ))
    .exhaustive();
};
