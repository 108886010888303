import {
  BalanceCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotEmpty, isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { match } from "ts-pattern";
import { TrackPressable } from "../components/TrackPressable";
import { TransactionListFragment, TransactionsOrderByInput } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";

type Props = {
  transactions: TransactionListFragment["edges"];
  onEndReached: () => void;
  perPage: number;
  isLoading: boolean;
  extraInfo: ExtraInfo;
  getRowLink?: (config: LinkConfig<Edge, ExtraInfo>) => ReactElement | undefined;
  emptyListTitle: string;
  hasSearchOrFilters: boolean;
};

type Edge = GetEdge<TransactionListFragment>;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: TransactionsOrderByInput) => void;
  sortBy?: TransactionsOrderByInput;
  reexecuteQuery: () => void;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 300,
    id: "account",
    title: t("transactions.account"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { account },
      },
      extraInfo: { projectId, projectEnv },
    }: ColumnCellConfig<Edge, ExtraInfo>) => {
      if (isNotNullish(account)) {
        const accountId = account.id;

        return (
          <LinkCell
            onPress={() => Router.push("AccountDetailRoot", { projectId, projectEnv, accountId })}
          >
            {`${account.name} - ${account.number}`}
          </LinkCell>
        );
      }
      return null;
    },
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 240,
    id: "accountHolder",
    title: t("transactions.accountHolder"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { account },
      },
      extraInfo: { projectId, projectEnv },
    }: ColumnCellConfig<Edge, ExtraInfo>) => {
      if (isNotNullish(account)) {
        const accountHolderId = account.holder.id;

        return (
          <LinkCell
            onPress={() =>
              Router.push("HoldersDetailRoot", {
                projectId,
                projectEnv,
                accountHolderId,
              })
            }
          >
            {account.holder.info.name}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 200,
    id: "executionDate",
    title: t("transactions.executionDate"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transations by execution date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "executionDate", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "executionDate"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { executionDate },
      },
    }) => (
      <TextCell text={dayjs(executionDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 130,
    id: "status",
    title: t("transactions.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { statusInfo },
      },
    }) => (
      <Cell>
        {match(statusInfo.status)
          .with("Booked", () => <Tag color="positive">{t("transactions.status.booked")}</Tag>)
          .with("Canceled", () => <Tag color="gray">{t("transactions.status.canceled")}</Tag>)
          .with("Pending", () => <Tag color="shakespear">{t("transactions.status.pending")}</Tag>)
          .with("Rejected", () => <Tag color="negative">{t("transactions.status.rejected")}</Tag>)
          .with("Released", () => <Tag color="gray">{t("transactions.status.released")}</Tag>)
          .with("Upcoming", () => <Tag color="shakespear">{t("transactions.status.upcoming")}</Tag>)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 240,
    id: "label",
    title: t("transactions.label"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { label },
      },
    }) => <TextCell text={label} />,
  },
  {
    width: 300,
    id: "id",
    title: t("transactions.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 240,
    id: "paymentProduct",
    title: t("transactions.paymentProduct"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentProduct },
      },
    }) => (
      <Cell>
        {match(paymentProduct)
          .with("Card", () => <Tag color="gray">{t("transactions.paymentProduct.card")}</Tag>)
          .with("Check", () => <Tag color="gray">{t("transactions.paymentProduct.check")}</Tag>)
          .with("Fees", () => <Tag color="gray">{t("transactions.paymentProduct.fees")}</Tag>)
          .with("InternalCreditTransfer", () => (
            <Tag color="gray">{t("transactions.paymentProduct.internalCreditTransfer")}</Tag>
          ))
          .with("InternalDirectDebit", () => (
            <Tag color="gray">{t("transactions.paymentProduct.internalDirectDebit")}</Tag>
          ))
          .with("InternationalCreditTransfer", () => (
            <Tag color="gray">{t("transactions.paymentProduct.internationalCreditTransfer")}</Tag>
          ))
          .with("SEPACreditTransfer", () => (
            <Tag color="gray">{t("transactions.paymentProduct.SEPACreditTransfer")}</Tag>
          ))
          .with("SEPADirectDebit", () => (
            <Tag color="gray">{t("transactions.paymentProduct.SEPADirectDebit")}</Tag>
          ))
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 290,
    id: "type",
    title: t("transactions.type"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { type },
      },
    }) => (
      <Cell>
        <Tag color="gray">{type}</Tag>
      </Cell>
    ),
  },
  {
    width: 350,
    id: "paymentMethod",
    title: t("transactions.paymentMethod"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentMethodIdentifier },
      },
    }) => (
      <CopyableTextCell
        text={paymentMethodIdentifier}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 240,
    id: "counterParty",
    title: t("transactions.counterParty"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { counterparty },
      },
    }) => <TextCell text={counterparty} />,
  },
  {
    width: 240,
    id: "createdAt",
    title: t("transactions.createdAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transations by creation date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 240,
    id: "updatedAt",
    title: t("transactions.updatedAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transations by update date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 240,
    id: "reference",
    title: t("transactions.reference"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { reference },
      },
    }) => <TextCell text={isNotEmpty(reference) ? reference : "-"} />,
  },
  {
    width: 240,
    id: "externalReference",
    title: t("transactions.externalReference"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { externalReference },
      },
    }) => <TextCell text={isNotNullishOrEmpty(externalReference) ? externalReference : "-"} />,
  },
  {
    width: 240,
    id: "bookedBalanceAfter",
    title: t("transactions.bookedBalanceAfter"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { bookedBalanceAfter },
      },
    }) => (
      <TextCell
        text={
          isNotNullish(bookedBalanceAfter) && bookedBalanceAfter.value !== ""
            ? formatCurrency(Number(bookedBalanceAfter.value), bookedBalanceAfter.currency)
            : "-"
        }
      />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 150,
    id: "amount",
    title: t("transaction.amount"),
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: ({ item: { node } }: ColumnCellConfig<Edge, ExtraInfo>) => {
      const {
        amount: { currency, value },
        side,
      } = node;

      const originalAmount = match(node)
        .with({ __typename: "CardTransaction" }, ({ amount: { value, currency } }) => {
          const unsigned = Number(value);
          return {
            value:
              unsigned *
              (unsigned === 0
                ? 1
                : match(side)
                    .with("Debit", () => -1)
                    .with("Credit", () => 1)
                    .exhaustive()),
            currency,
          };
        })
        .otherwise(() => undefined);

      const unsigned = Number(value);
      return (
        <BalanceCell
          currency={currency}
          value={
            unsigned *
            (unsigned === 0
              ? 1
              : match(side)
                  .with("Debit", () => -1)
                  .with("Credit", () => 1)
                  .exhaustive())
          }
          originalValue={originalAmount}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 48,
    id: "actions",
    title: t("common.table.actions"),
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell align="right">
        <Icon
          name="chevron-right-filled"
          color={isHovered ? colors.gray[700] : colors.gray[200]}
          size={16}
        />
      </Cell>
    ),
  },
];

export const TransactionsList = ({
  transactions,
  onEndReached,
  isLoading,
  perPage,
  extraInfo,
  getRowLink,
  emptyListTitle,
  hasSearchOrFilters,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      marginHorizontal={negativeSpacings[24]}
      extraInfo={extraInfo}
      keyExtractor={keyExtractor}
      data={transactions}
      stickedToStartColumns={stickedToStartColumns}
      columns={columns}
      stickedToEndColumns={stickedToEndColumns}
      onEndReached={onEndReached}
      headerHeight={48}
      rowHeight={48}
      getRowLink={getRowLink}
      loading={{
        isLoading,
        count: perPage,
      }}
      renderEmptyList={() =>
        hasSearchOrFilters ? (
          <EmptyView
            icon="clipboard-search-regular"
            title={t("common.list.noResults")}
            subtitle={t("common.list.noResultsSuggestion")}
          />
        ) : (
          <EmptyView icon="lake-inbox-empty" title={emptyListTitle} />
        )
      }
    />
  );
};
