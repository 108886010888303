import { AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  FilterInputDef,
  FilterRadioDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import dayjs from "dayjs";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Image, StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import applePayLogo from "../assets/img/apple-pay.svg";
import googlePayLogo from "../assets/img/google-pay.svg";
import {
  CompleteDigitalCardStatus,
  GetDigitalCardsDocument,
  GetDigitalCardsQuery,
  PendingDigitalCardStatus,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { Card } from "../pages/CardDetailsPage";
import { getCardHolderName } from "../utils/card";
import { t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { CancelCardDigital } from "./CardModals";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  providerLogo: {
    width: 36,
    height: 22,
  },
});

type CardStatus = PendingDigitalCardStatus | CompleteDigitalCardStatus;
const statuses = [
  "Canceled",
  "ConsentPending",
  "Declined",
  "Enabled",
  "Pending",
  "Suspended",
] as const;

type WalletProvider = "ApplePay" | "GooglePay";
const walletProviders = ["ApplePay", "GooglePay"] as const;

type Edge = GetEdge<NonNullable<GetDigitalCardsQuery["card"]>["digitalCards"]>;

type ExtraInfo = {
  projectId: string;
  onCancelCard: (card: Card) => void;
  projectEnv: ProjectEnv;
};

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 100,
    id: "walletProviderName",
    title: t("cards.digitalCards.provider"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { walletProvider },
      },
    }) => (
      <Cell>
        {match(walletProvider.name)
          .with("ApplePay", () => (
            <Image
              resizeMode="contain"
              source={{ uri: applePayLogo }}
              style={styles.providerLogo}
            />
          ))
          .with("GooglePay", () => (
            <Image
              resizeMode="contain"
              source={{ uri: googlePayLogo }}
              style={styles.providerLogo}
            />
          ))
          .otherwise(() => (
            <LakeText color={colors.gray[900]}>{walletProvider.name}</LakeText>
          ))}
      </Cell>
    ),
  },
  {
    width: 230,
    id: "type",
    title: t("cards.digitalCards.type"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { type },
      },
    }) => {
      const item = match(type)
        .returnType<{ iconName?: IconName; text: string }>()
        .with("InApp", () => ({
          iconName: "phone-regular",
          text: t("cards.digitalCards.type.inApp"),
        }))
        .with("CardOnFile", () => ({
          iconName: "device-meeting-room-regular",
          text: t("cards.digitalCards.type.cardOnFile"),
        }))
        .with("Manual", () => ({
          iconName: "hand-right-regular",
          text: t("cards.digitalCards.type.manual"),
        }))
        .with("Unknown", () => ({
          text: t("cards.digitalCards.type.unknown"),
        }))
        .exhaustive();

      return (
        <Cell>
          <Tag color="live" icon={item.iconName}>
            {item.text}
          </Tag>
        </Cell>
      );
    },
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 230,
    id: "createdAt",
    title: t("cards.digitalCards.creationDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => {
      return <TextCell text={dayjs(createdAt).format("LLL")} />;
    },
  },
  {
    width: 230,
    id: "updatedDate",
    title: t("cards.digitalCards.updatedDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => {
      return match(node)
        .with(
          { __typename: "CompleteDigitalCard" },
          { __typename: "PendingDigitalCard" },
          ({ updatedAt }) => <TextCell text={dayjs(updatedAt).format("LLL")} />,
        )
        .otherwise(() => null);
    },
  },
  {
    width: 230,
    id: "enabledDate",
    title: t("cards.digitalCards.enabledDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => {
      return match(node)
        .with({ __typename: "CompleteDigitalCard" }, ({ completedStatusInfo }) => (
          <TextCell text={dayjs(completedStatusInfo.enabledAt).format("LLL")} />
        ))
        .otherwise(() => null);
    },
  },
  {
    width: 230,
    id: "suspendedDate",
    title: t("cards.digitalCards.suspendedDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => {
      return match(node)
        .with({ __typename: "CompleteDigitalCard" }, ({ completedStatusInfo }) =>
          match(completedStatusInfo)
            .with({ __typename: "DigitalCardSuspendedStatusInfo" }, ({ suspendedAt }) => (
              <TextCell text={dayjs(suspendedAt).format("LLL")} />
            ))
            .otherwise(() => null),
        )
        .otherwise(() => null);
    },
  },
  {
    width: 230,
    id: "canceledDate",
    title: t("cards.digitalCards.canceledDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => {
      return match(node)
        .with({ __typename: "CompleteDigitalCard" }, ({ completedStatusInfo }) =>
          match(completedStatusInfo)
            .with({ __typename: "DigitalCardCanceledStatusInfo" }, ({ canceledAt }) => (
              <TextCell text={dayjs(canceledAt).format("LLL")} />
            ))
            .otherwise(() => null),
        )
        .otherwise(() => null);
    },
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 120,
    id: "status",
    title: t("cards.digitalCards.status"),
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: ({ item: { node } }) => {
      return (
        <Cell align="right">
          <LakeText color={colors.gray[900]}>
            {match(node)
              .with({ __typename: "CompleteDigitalCard" }, ({ completedStatusInfo }) =>
                match(completedStatusInfo.status)
                  .with("Canceled", () => (
                    <Tag color="gray">{t("cards.digitalCards.status.canceled")}</Tag>
                  ))
                  .with("Enabled", () => (
                    <Tag color="positive">{t("cards.digitalCards.status.enabled")}</Tag>
                  ))
                  .with("Suspended", () => (
                    <Tag color="warning">{t("cards.digitalCards.status.suspended")}</Tag>
                  ))
                  .exhaustive(),
              )
              .with({ __typename: "PendingDigitalCard" }, ({ pendingDigitalInfo }) =>
                match(pendingDigitalInfo.status)
                  .with("ConsentPending", () => (
                    <Tag color="gray">{t("cards.digitalCards.status.consentPending")}</Tag>
                  ))
                  .with("Declined", () => (
                    <Tag color="negative">{t("cards.digitalCards.status.declined")}</Tag>
                  ))
                  .with("Pending", () => (
                    <Tag color="shakespear">{t("cards.digitalCards.status.pending")}</Tag>
                  ))
                  .exhaustive(),
              )
              .exhaustive()}
          </LakeText>
        </Cell>
      );
    },
  },
  {
    width: 100,
    id: "actions",
    title: "",
    renderTitle: () => null,
    renderCell: ({ item: { node }, extraInfo: { onCancelCard } }) => (
      <Cell align="right">
        {match(node)
          .with({ __typename: "CompleteDigitalCard" }, ({ completedStatusInfo }) =>
            match(completedStatusInfo.status)
              .with("Enabled", () => (
                <TrackPressable action="Cancel digital card">
                  <Pressable
                    onPress={event => {
                      event.preventDefault();
                      onCancelCard(node as unknown as Card);
                    }}
                  >
                    {({ hovered }) => (
                      <Icon
                        name="subtract-circle-regular"
                        color={hovered ? colors.negative[500] : colors.gray[200]}
                        size={16}
                      />
                    )}
                  </Pressable>
                </TrackPressable>
              ))
              .otherwise(() => null),
          )
          .otherwise(() => null)}
      </Cell>
    ),
  },
];

const PER_PAGE = 20;

const keyExtractor = ({ node }: Edge) => node.id;

type Props = {
  cardId: string;
  params: RouteParams<"CardDetailDigitalCards">;
};

export const CardDetailsDigitalCards = ({ cardId, params }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();

  const filters: Filters = useMemo(
    () => ({
      status: match(params.status)
        .with(...statuses, value => value)
        .otherwise(() => undefined),
      walletProviderName: match(params.walletProviderName)
        .with(...walletProviders, value => value)
        .otherwise(() => undefined),
      cardMaskedNumber: params.cardMaskedNumber,
    }),
    [params.status, params.walletProviderName, params.cardMaskedNumber],
  );

  const hasFilters = Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetDigitalCardsDocument, {
    cardId,
    first: PER_PAGE,
    filters,
  });

  const [cancelCardInfo, setCancelCardInfo] = useState<Card | null>(null);

  const extraInfo: ExtraInfo = useMemo(() => {
    return { projectEnv, projectId, onCancelCard: digitalCard => setCancelCardInfo(digitalCard) };
  }, [projectEnv, projectId]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ card }) => Option.fromNullable(card?.digitalCards.totalCount));

  return (
    <>
      <DigitalFilterForm
        onRefresh={reload}
        totalCount={totalCount}
        filters={filters}
        onChangeFilters={filters =>
          Router.replace("CardDetailDigitalCards", {
            projectId,
            projectEnv,
            cardId,
            ...filters,
          })
        }
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder headerHeight={48} rowHeight={48} count={20} />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.card?.digitalCards}>
            {digitalCards => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={digitalCards?.edges ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                columns={columns}
                onEndReached={() => {
                  if (digitalCards?.pageInfo.hasNextPage === true) {
                    setVariables({ after: digitalCards?.pageInfo.endCursor ?? undefined });
                  }
                }}
                stickedToStartColumns={stickedToStartColumns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                loading={{ isLoading, count: PER_PAGE }}
                rowHeight={48}
                renderEmptyList={() =>
                  hasFilters ? (
                    <EmptyView
                      icon="clipboard-search-regular"
                      title={t("common.list.noResults")}
                      subtitle={t("common.list.noResultsSuggestion")}
                    />
                  ) : (
                    <EmptyView icon="lake-inbox-empty" title={t("cards.list.emptyTitle")} />
                  )
                }
              />
            )}
          </Connection>
        ))
        .exhaustive()}

      <CancelCardDigital
        visible={isNotNullish(cancelCardInfo)}
        cardId={cancelCardInfo?.id ?? ""}
        cardHolderName={data
          .toOption()
          .flatMap(result => result.toOption())
          .flatMap(data => Option.fromNullable(data.card))
          .flatMap(getCardHolderName)
          .getOr("")}
        onClose={shouldReload => {
          setCancelCardInfo(null);
          if (shouldReload) {
            reload();
          }
        }}
      />
    </>
  );
};

const statusFilter: FilterRadioDef<CardStatus | undefined> = {
  type: "radio",
  label: t("cards.digitalCards.status"),
  items: [
    { value: undefined, label: t("cards.filters.all") },
    {
      value: "Canceled",
      label: t("cards.digitalCards.status.canceled"),
    },
    {
      value: "Enabled",
      label: t("cards.digitalCards.status.enabled"),
    },
    {
      value: "Suspended",
      label: t("cards.digitalCards.status.suspended"),
    },
    {
      value: "ConsentPending",
      label: t("cards.digitalCards.status.consentPending"),
    },
    {
      value: "Declined",
      label: t("cards.digitalCards.status.declined"),
    },
    {
      value: "Pending",
      label: t("cards.digitalCards.status.pending"),
    },
  ],
};

const walletProviderFilter: FilterRadioDef<WalletProvider | undefined> = {
  type: "radio",
  label: t("cards.digitalCards.provider"),
  items: [
    { value: undefined, label: t("cards.filters.all") },
    // No need to translate provider labels.
    { value: "ApplePay", label: "Apple Pay" },
    { value: "GooglePay", label: "Google Pay" },
  ],
};

const cardMaskedNumberFilter: FilterInputDef = {
  type: "input",
  label: t("cards.digitalCards.cardMaskedNumber"),
  noValueText: t("common.none"),
  placeholder: t("cards.digitalCards.cardMaskedNumberPlaceholder"),
};

const filtersDefinition = {
  status: statusFilter,
  walletProviderName: walletProviderFilter,
  cardMaskedNumber: cardMaskedNumberFilter,
};

type Filters = FiltersState<typeof filtersDefinition>;

type FilterFormProps = {
  filters: Filters;
  totalCount: Option<number>;
  onRefresh: () => Future<unknown>;
  onChangeFilters: (filters: Filters) => void;
};

const DigitalFilterForm = ({
  filters,
  totalCount,
  onRefresh,
  onChangeFilters,
}: FilterFormProps) => {
  const availableFilters: { name: keyof Filters; label: string }[] = useMemo(
    () => [
      {
        name: "status",
        label: t("cards.digitalCards.status"),
      },
      {
        name: "walletProviderName",
        label: t("cards.digitalCards.provider"),
      },
      {
        name: "cardMaskedNumber",
        label: t("cards.digitalCards.cardMaskedNumber"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([_name, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser<keyof Filters>
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          title={t("webhook.filters.choose")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={16} />

        <TrackPressable action="Cancel digital card">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        {totalCount
          .map<ReactNode>(count => (
            <Tag size="large" color="partner">
              {t("card.counter", { count })}
            </Tag>
          ))
          .toNull()}
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
