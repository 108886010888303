import { useFocusReset } from "@swan-io/chicane";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { ReactNode, useRef } from "react";
import { View } from "react-native";
import { ValueOf } from "type-fest";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { RouteParams, Router, simulatorSubRoutes } from "../utils/routes";

type SimulatorSubRoute = (typeof simulatorSubRoutes)[number];

type SimulatorSubRouteTab = ValueOf<{
  [K in SimulatorSubRoute]: {
    url: K;
    label: string;
    render: (params: RouteParams<K>) => ReactNode;
  };
}>;

type SimulatorTabsProps = {
  selected?: SimulatorSubRoute;
  tabs: SimulatorSubRouteTab[];
};

export const SimulatorTabs = ({ tabs, selected }: SimulatorTabsProps) => {
  const { projectId } = useProjectInfo();

  const containerRef = useRef<View | null>(null);
  const selectedTab = tabs.find(({ url }) => url === selected) ?? undefined;
  const route = Router.useRoute(simulatorSubRoutes);

  useFocusReset({ route, containerRef });

  if (route == null) {
    return <NotFoundPage />;
  }

  return (
    <>
      <TabView
        hideIfSingleItem={false}
        tabs={tabs.map(tab => ({
          url: Router[tab.url]({ projectId }),
          label: tab.label,
        }))}
        otherLabel={t("common.tabs.other")}
      />

      <Space height={20} />

      <LakeScrollView>
        <View ref={containerRef}>{selectedTab?.render(route.params) ?? <NotFoundPage />}</View>
        <Space height={16} />
      </LakeScrollView>
    </>
  );
};
