import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import { match, P } from "ts-pattern";
import {
  IdentificationFragment,
  IdentificationInvalidReason,
  IdentificationLevel,
  IdentificationLevelFragment,
  IdentificationProcess,
  SwanIdentificationStatus,
  UserIdentificationsDocument,
  UserIdentificationsQuery,
} from "../graphql/partner";
import { isTranslationKey, t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const translateReason = (reason: IdentificationInvalidReason) => {
  try {
    return match(`user.details.identifications.reasons.${reason}`)
      .with(P.when(isTranslationKey), key => t(key))
      .exhaustive();
  } catch {
    return t("user.details.identifications.reasons.unknown");
  }
};

const PER_PAGE = 20;

type PatchedQuery = Omit<UserIdentificationsQuery, "user"> & {
  user?:
    | (Omit<UserIdentificationsQuery["user"], "identifications"> & {
        identifications?:
          | (Omit<
              NonNullable<NonNullable<UserIdentificationsQuery["user"]>["identifications"]>,
              "pageInfo"
            > & {
              pageInfo: NonNullable<
                NonNullable<
                  NonNullable<UserIdentificationsQuery["user"]>["identifications"]
                >["pageInfo"]
              >;
            })
          | null
          | undefined;
      })
    | null
    | undefined;
};

type ExtraInfo = undefined;

const keyExtractor = (node: IdentificationFragment) => node.id;

const LevelStatusCell = ({ level }: { level: IdentificationLevelFragment }) => {
  const [invalidReasons, setInvalidReasons] = useState<Option<IdentificationInvalidReason[]>>(
    Option.None(),
  );
  const buttonRef = useRef<View>(null);

  const status = match(level)
    .returnType<SwanIdentificationStatus>()
    .with({ __typename: "NotStartedIdentificationLevelStatusInfo" }, () => "Started")
    .otherwise(level => level.status);

  return (
    <Cell>
      {match(status)
        .with("NotStarted", () => (
          <Tag color="gray">{t("user.details.identifications.status.started")}</Tag>
        ))
        .with("Canceled", () => (
          <Tag color="gray">{t("user.details.identifications.status.canceled")}</Tag>
        ))
        .with("Expired", () => (
          <Tag color="gray">{t("user.details.identifications.status.expired")}</Tag>
        ))
        .with("Invalid", () => (
          <Tag color="negative">{t("user.details.identifications.status.invalid")}</Tag>
        ))
        .with("NotSupported", () => <LakeText>{"-"}</LakeText>)
        .with("Pending", () => (
          <Tag color="shakespear">{t("user.details.identifications.status.pending")}</Tag>
        ))
        .with("Started", () => (
          <Tag color="shakespear">{t("user.details.identifications.status.started")}</Tag>
        ))
        .with("Valid", () => (
          <Tag color="positive">{t("user.details.identifications.status.valid")}</Tag>
        ))
        .exhaustive()}

      {match(level)
        .with(
          {
            __typename: "InvalidIdentificationLevelStatusInfo",
            reasons: P.intersection(P.nonNullable, P.not([])),
          },
          ({ reasons }) => (
            <>
              <Space width={8} />

              <LakeButton
                ref={buttonRef}
                size="small"
                mode="tertiary"
                icon="info-regular"
                onPress={() => setInvalidReasons(Option.Some(reasons))}
                ariaLabel={t("common.moreInfo")}
              />
            </>
          ),
        )
        .otherwise(() => null)}

      <Popover
        referenceRef={buttonRef}
        visible={invalidReasons.isSome()}
        underlay={true}
        onDismiss={() => setInvalidReasons(Option.None())}
      >
        {invalidReasons
          .map(reasons => (
            <LakeAlert
              variant="error"
              title={t("user.details.identifications.reason")}
              callToAction={
                <LakeButton
                  onPress={() => setInvalidReasons(Option.None())}
                  icon="lake-close"
                  mode="tertiary"
                  size="small"
                  color="negative"
                  ariaLabel={t("common.close")}
                />
              }
            >
              {reasons.map((item, index) => (
                <LakeText color={colors.gray[500]} variant="regular" key={index}>
                  {translateReason(item)}
                </LakeText>
              ))}
            </LakeAlert>
          ))
          .toNull()}
      </Popover>
    </Cell>
  );
};

const stickedToStartColumns: ColumnConfig<IdentificationFragment, ExtraInfo>[] = [
  {
    id: "process",
    width: 120,
    title: t("user.details.identifications.process"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { process } }) => (
      <Cell>
        <Tag color="gray">
          {match(process)
            .with("Expert", () => t("user.details.identifications.process.expert"))
            .with("QES", () => t("user.details.identifications.process.qes"))
            .with("PVID", () => t("user.details.identifications.process.pvid"))
            .exhaustive()}
        </Tag>
      </Cell>
    ),
  },
];

const columns: ColumnConfig<IdentificationFragment, ExtraInfo>[] = [
  {
    width: 400,
    id: "id",
    title: t("user.details.identifications.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { id } }) => {
      return (
        <CopyableTextCell
          text={id}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      );
    },
  },
  {
    id: "updatedAt",
    width: 130,
    title: t("user.details.identifications.updatedAt"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { updatedAt } }) => (
      <TextCell
        variant="smallRegular"
        color={colors.gray[900]}
        text={dayjs(updatedAt).format("LL")}
      />
    ),
  },

  {
    id: "expert",
    width: 100,
    title: t("user.details.identifications.level.expert"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { levels } }) => <LevelStatusCell level={levels.expert} />,
  },
  {
    id: "qes",
    width: 100,
    title: t("user.details.identifications.level.qes"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { levels } }) => <LevelStatusCell level={levels.qes} />,
  },
  {
    id: "pvid",
    width: 100,
    title: t("user.details.identifications.level.pvid"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { levels } }) => <LevelStatusCell level={levels.pvid} />,
  },
];

type Props = {
  userId: string;
  params: RouteParams<"UserDetailIdentifications">;
};

const levels = deriveUnion<IdentificationLevel>({
  Expert: true,
  QES: true,
  PVID: true,
});

const processes = deriveUnion<IdentificationProcess>({
  Expert: true,
  QES: true,
  PVID: true,
});

const statuses = deriveUnion<SwanIdentificationStatus>({
  Canceled: true,
  Expired: true,
  Invalid: true,
  NotStarted: true,
  NotSupported: true,
  Pending: true,
  Started: true,
  Valid: true,
});

export const UserDetailIdentifications = ({ userId, params }: Props) => {
  const filters = useMemo(() => {
    return {
      levels: Option.fromNullable(params.levels)
        .map(levelParams =>
          Array.filterMap(levelParams, level =>
            match(level).with(levels.P, Option.Some).otherwise(Option.None),
          ),
        )
        .toUndefined(),
      processes: Option.fromNullable(params.processes)
        .map(processesParams =>
          Array.filterMap(processesParams, level =>
            match(level).with(processes.P, Option.Some).otherwise(Option.None),
          ),
        )
        .toUndefined(),
      statuses: Option.fromNullable(params.statuses)
        .map(statusesParams =>
          Array.filterMap(statusesParams, level =>
            match(level).with(statuses.P, Option.Some).otherwise(Option.None),
          ),
        )
        .toUndefined(),
    };
  }, [params.levels, params.processes, params.statuses]);

  const [data, { isLoading, reload, setVariables }] = useQuery(UserIdentificationsDocument, {
    userId,
    first: PER_PAGE,
    filters,
  });

  const hasFilters = Object.values(filters).some(isNotNullish);

  const patchedData = useMemo(() => {
    return data.mapOkToResult(data =>
      data.user?.identifications?.pageInfo == null
        ? Result.Error(undefined)
        : Result.Ok(data as PatchedQuery),
    );
  }, [data]);

  return (
    <>
      <IdentificationFiltersForm
        filters={filters}
        onRefresh={reload}
        onChangeFilters={filters =>
          Router.replace("UserDetailIdentifications", { ...params, ...filters })
        }
      />

      {match(patchedData)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.user?.identifications}>
            {identifications => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={identifications?.edges.map(edge => edge.node) ?? []}
                keyExtractor={keyExtractor}
                extraInfo={undefined}
                stickedToStartColumns={stickedToStartColumns}
                columns={columns}
                onEndReached={() => {
                  if (identifications?.pageInfo.hasNextPage === true) {
                    setVariables({ after: identifications?.pageInfo.endCursor ?? undefined });
                  }
                }}
                headerHeight={48}
                rowHeight={48}
                loading={{ isLoading, count: PER_PAGE }}
                renderEmptyList={() =>
                  hasFilters ? (
                    <EmptyView
                      icon="clipboard-search-regular"
                      title={t("common.list.noResults")}
                      subtitle={t("common.list.noResultsSuggestion")}
                    />
                  ) : (
                    <EmptyView icon="lake-inbox-empty" title={t("common.list.noResults")} />
                  )
                }
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const statusFilter: FilterCheckboxDef<SwanIdentificationStatus> = {
  type: "checkbox",
  label: t("user.details.identifications.status"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Canceled", label: t("user.details.identifications.status.canceled") },
    { value: "Expired", label: t("user.details.identifications.status.expired") },
    { value: "Invalid", label: t("user.details.identifications.status.invalid") },
    { value: "NotSupported", label: t("user.details.identifications.status.notSupported") },
    { value: "Pending", label: t("user.details.identifications.status.pending") },
    { value: "Started", label: t("user.details.identifications.status.started") },
    { value: "Valid", label: t("user.details.identifications.status.valid") },
  ],
};

const levelFilter: FilterCheckboxDef<IdentificationLevel> = {
  type: "checkbox",
  label: t("user.details.identifications.level"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Expert", label: t("user.details.identifications.level.expert") },
    { value: "QES", label: t("user.details.identifications.level.qes") },
    { value: "PVID", label: t("user.details.identifications.level.pvid") },
  ],
};

const processFilter: FilterCheckboxDef<IdentificationProcess> = {
  type: "checkbox",
  label: t("user.details.identifications.process"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Expert", label: t("user.details.identifications.process.expert") },
    { value: "QES", label: t("user.details.identifications.process.qes") },
    { value: "PVID", label: t("user.details.identifications.process.pvid") },
  ],
};

const filtersDefinition = {
  statuses: statusFilter,
  levels: levelFilter,
  processes: processFilter,
};

type IdentificationFilters = FiltersState<typeof filtersDefinition>;

type IdentificationFiltersFormProps = {
  filters: IdentificationFilters;
  onChangeFilters: (filters: IdentificationFilters) => void;
  onRefresh: () => Future<unknown>;
};

const IdentificationFiltersForm = ({
  filters,
  onChangeFilters,
  onRefresh,
}: IdentificationFiltersFormProps) => {
  const availableFilters: { name: keyof IdentificationFilters; label: string }[] = useMemo(
    () => [
      {
        name: "statuses",
        label: t("user.details.identifications.status"),
      },
      {
        name: "levels",
        label: t("user.details.identifications.level"),
      },
      {
        name: "processes",
        label: t("user.details.identifications.process"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={16} />

        <TrackPressable action="Refresh identifications list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
