import { Future, Option, Result } from "@swan-io/boxed";
import { ClientError, useDeferredQuery, useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors, radii, spacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  isNotNullish,
  isNotNullishOrEmpty,
  isNullish,
  isNullishOrEmpty,
} from "@swan-io/lake/src/utils/nullish";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { countries } from "@swan-io/shared-business/src/constants/countries";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { isValid, printFormat as printIbanFormat } from "iban";
import { useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import {
  CancelTransactionDocument,
  GenerateTransactionStatementDocument,
  GetTransactionQuery,
  MerchantCategory,
  TransactionFragment,
  TransactionStatementDocument,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { printMaskedPanFormat } from "../utils/card";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import {
  getTransactionRejectedReasonLabel,
  getWiseIctLabel,
  tTransactionCategory,
} from "../utils/templateTranslations";
import { PaymentProductTag } from "./PaymentProductTag";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  grows: { flex: 1 },
  cancelTransaction: {
    width: 200,
  },
  line: {
    flexDirection: "row",
    alignItems: "center",
  },
  unknownValue: {
    fontStyle: "italic",
  },
  merchantName: {
    display: "flex",
    wordBreak: "break-all",
    flexDirection: "row",
    alignItems: "center",
  },
  merchantLogo: {
    width: spacings[20],
    height: spacings[20],
    borderRadius: radii[4],
  },
});

const CancelTransactionModal = ({
  transactionId,
  onClose,
}: {
  transactionId: string;
  onClose: () => void;
}) => {
  const [cancelTransaction, transactionCancelation] = useMutation(CancelTransactionDocument);

  const onPressCancelTransaction = () => {
    cancelTransaction({ input: { transactionId } })
      .mapOk(data => data.cancelTransaction)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(onClose)
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <>
      <Icon size={40} name="subtract-circle-regular" color={colors.negative[500]} />
      <Space height={16} />

      <LakeHeading level={3} variant="h3">
        {t("transaction.cancel.modalTitle")}
      </LakeHeading>

      <Space height={32} />

      <Box direction="row">
        <TrackPressable action="Cancel transaction cancellation">
          <LakeButton style={styles.grows} mode="secondary" onPress={onClose}>
            {t("transaction.cancel.cancelConfirm")}
          </LakeButton>
        </TrackPressable>

        <Space width={24} />

        <TrackPressable action="Confirm transaction cancellation">
          <LakeButton
            loading={transactionCancelation.isLoading()}
            style={styles.grows}
            icon="subtract-circle-filled"
            color="negative"
            onPress={() => onPressCancelTransaction()}
          >
            {t("transaction.cancel.confirm")}
          </LakeButton>
        </TrackPressable>
      </Box>
    </>
  );
};

type Props = {
  transaction: TransactionFragment;
  reload: () => Future<Result<GetTransactionQuery, ClientError>>;
};

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

const merchantCategoryIcons: Record<MerchantCategory, IconName> = {
  Culture: "music-note-2-regular",
  Entertainment: "movies-and-tv-regular",
  Finance: "calculator-regular",
  Groceries: "cart-regular",
  HealthAndBeauty: "heart-pulse-regular",
  HomeAndUtilities: "home-regular",
  Other: "payment-regular",
  ProfessionalServices: "people-team-toolbox-regular",
  PublicAdministrations: "gavel-regular",
  Restaurants: "food-regular",
  Shopping: "shopping-bag-regular",
  Software: "laptop-regular",
  Transport: "vehicle-subway-regular",
  Travel: "airplane-regular",
};

export const getMerchantCategoryIcon = (category: MerchantCategory) =>
  merchantCategoryIcons[category];

export const TransactionDetail = ({ transaction, reload }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [cancelTransactionVisible, { open: openCancelTransaction, close: closeCancelTransaction }] =
    useDisclosure(false);

  const [generateTransactionStatement] = useMutation(GenerateTransactionStatementDocument);
  const [, { query: queryTransactionStatement }] = useDeferredQuery(TransactionStatementDocument);

  const [isGeneratingStatement, setIsGeneratingStatement] = useState(false);

  const generateStatement = () => {
    setIsGeneratingStatement(true);
    generateTransactionStatement({
      input: { transactionId: transaction.id, language: transaction.account?.language },
    })
      .mapOk(data => data.generateTransactionStatement)
      .mapOkToResult(filterRejectionsToResult)
      .mapOkToResult(data => Option.fromNullable(data.transactionStatement).toResult(new Error()))
      .flatMapOk(({ id }) =>
        Future.retry(
          () =>
            Future.wait(1000).flatMap(() =>
              queryTransactionStatement({ id }).mapOkToResult(data =>
                match(data.transactionStatement)
                  .with(
                    {
                      statusInfo: {
                        __typename: "GeneratedTransactionStatementStatusInfo",
                        url: P.select(),
                      },
                    },
                    url => Result.Ok(url),
                  )
                  .otherwise(value => Result.Error(value)),
              ),
            ),
          { max: 20 },
        ),
      )
      .tap(() => setIsGeneratingStatement(false))
      .tapOk(url => window.location.replace(url))
      .tapError(error => showToast({ variant: "error", title: translateError(error), error }));
  };

  const payment = transaction.payment;
  const account = transaction.account;

  return (
    <LakeScrollView>
      {match(transaction.statusInfo)
        .with({ __typename: "RejectedTransactionStatusInfo" }, ({ reason }) => (
          <>
            <LakeAlert title={getTransactionRejectedReasonLabel(reason)} variant="error" />
            <Space height={24} />
          </>
        ))
        .otherwise(() => null)}

      <TileGrid>
        <Tile
          title={t("transaction.transaction")}
          headerEnd={
            transaction.statementCanBeGenerated === true ? (
              <LakeButton
                size="small"
                color="current"
                icon="arrow-download-filled"
                loading={isGeneratingStatement}
                onPress={() => generateStatement()}
              >
                {t("transaction.transactionConfirmation")}
              </LakeButton>
            ) : null
          }
        >
          <ReadOnlyFieldList>
            <LakeLabel
              type="view"
              label={t("transaction.id")}
              render={() => <LakeText color={colors.gray[900]}>{transaction.id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={transaction.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <LakeLabel
              type="view"
              label={t("transaction.executionDate")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(transaction.executionDate).format(
                    `${locale.dateFormat} ${locale.timeFormat}`,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("transaction.status")}
              render={() =>
                match(transaction.statusInfo.status)
                  .with("Booked", value => <Tag color="positive">{value}</Tag>)
                  .with("Rejected", value => <Tag color="negative">{value}</Tag>)
                  .with("Pending", value => <Tag color="shakespear">{value}</Tag>)
                  .with("Canceled", value => <Tag color="gray">{value}</Tag>)
                  .with("Upcoming", value => <Tag color="shakespear">{value}</Tag>)
                  .with("Released", value => <Tag color="gray">{value}</Tag>)
                  .exhaustive()
              }
            />

            {match(transaction.statusInfo)
              .with({ __typename: "RejectedTransactionStatusInfo" }, ({ reason }) => (
                <LakeLabel
                  type="view"
                  label={t("transaction.reason")}
                  render={() => (
                    <View style={styles.line}>
                      <Tag color="gray">{reason}</Tag>
                      <Space width={16} />
                      <InformationTooltip text={getTransactionRejectedReasonLabel(reason)} />
                    </View>
                  )}
                />
              ))
              .with(
                { __typename: "ReleasedTransactionStatusInfo" },
                ({ releaseReason, releaseDate }) => (
                  <ReadOnlyFieldList>
                    <LakeLabel
                      type="view"
                      label={t("transaction.releaseReason")}
                      render={() => <LakeText color={colors.gray[900]}>{releaseReason}</LakeText>}
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.releaseDate")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(releaseDate).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  </ReadOnlyFieldList>
                ),
              )
              .with(
                { __typename: "PendingTransactionStatusInfo" },
                ({ pendingEndDate }) =>
                  isNotNullish(pendingEndDate) && (
                    <LakeLabel
                      type="view"
                      label={t("transaction.pendingEndDate")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(pendingEndDate).format(
                            `${locale.dateFormat} ${locale.timeFormat}`,
                          )}
                        </LakeText>
                      )}
                    />
                  ),
              )
              .with({ __typename: "BookedTransactionStatusInfo" }, ({ bookingDate }) => (
                <LakeLabel
                  type="view"
                  label={t("transaction.bookingDate")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(bookingDate).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                    </LakeText>
                  )}
                />
              ))
              .with({ __typename: "CanceledTransactionStatusInfo" }, ({ canceledDate }) => (
                <LakeLabel
                  type="view"
                  label={t("transaction.cancelDate")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(canceledDate).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                    </LakeText>
                  )}
                />
              ))
              .otherwise(() => null)}

            {match(transaction)
              .with({ __typename: "CheckTransaction" }, ({ cmc7 }) => (
                <LakeLabel
                  type="view"
                  label={t("transaction.checkNumber")}
                  // The check number is the first 7 numbers of the cmc7
                  render={() => <LakeText color={colors.gray[900]}>{cmc7.slice(0, 7)}</LakeText>}
                />
              ))
              .otherwise(() => (
                <LakeLabel
                  type="view"
                  label={t("transaction.label")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {isNotNullishOrEmpty(transaction.label) ? transaction.label : UNKNOWN_VALUE}
                    </LakeText>
                  )}
                />
              ))}

            <LakeLabel
              type="view"
              label={t("transaction.amount")}
              render={() => {
                const unsigned = Number(transaction.amount.value);
                const value =
                  unsigned *
                  (unsigned === 0
                    ? 1
                    : match(transaction.side)
                        .with("Debit", () => -1)
                        .with("Credit", () => 1)
                        .exhaustive());
                return (
                  <LakeText
                    style={{
                      color:
                        unsigned === 0
                          ? colors.gray[900]
                          : transaction.side === "Credit"
                            ? colors.positive.primary
                            : colors.negative.primary,
                    }}
                  >
                    {value > 0 && "+"}
                    {formatCurrency(value, transaction.amount.currency)}
                  </LakeText>
                );
              }}
            />

            <LakeLabel
              type="view"
              label={t("transaction.paymentProduct")}
              render={() => <PaymentProductTag paymentProduct={transaction.paymentProduct} />}
            />

            <LakeLabel
              type="view"
              label={t("transaction.type")}
              render={() => <Tag color="gray">{transaction.type}</Tag>}
            />

            {match(transaction)
              .with({ type: P.not("CardInCredit") }, () => (
                <LakeLabel
                  type="view"
                  label={t("transaction.paymentMethod")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {isValid(transaction.paymentMethodIdentifier)
                        ? printIbanFormat(transaction.paymentMethodIdentifier)
                        : printMaskedPanFormat(transaction.paymentMethodIdentifier)}
                    </LakeText>
                  )}
                />
              ))
              .otherwise(() => null)}

            {match(transaction)
              .with(
                {
                  __typename: P.not("CardTransaction"),
                },
                {
                  cardDetails: {
                    __typename: P.not("CardInDetails"),
                  },
                },
                () => (
                  <LakeLabel
                    type="view"
                    label={t("transaction.counterparty")}
                    render={() =>
                      isNullishOrEmpty(transaction.counterparty) ? (
                        UNKNOWN_VALUE
                      ) : (
                        <LakeText color={colors.gray[900]}>{transaction.counterparty}</LakeText>
                      )
                    }
                  />
                ),
              )
              .otherwise(() => null)}

            <LakeLabel
              type="view"
              label={t("transaction.createdAt")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(transaction.createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("transaction.updatedAt")}
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(transaction.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                </LakeText>
              )}
            />

            <LakeLabel
              type="view"
              label={t("transaction.reference")}
              render={() =>
                isNullishOrEmpty(transaction.reference) ? (
                  UNKNOWN_VALUE
                ) : (
                  <LakeText color={colors.gray[900]}>{transaction.reference}</LakeText>
                )
              }
              actions={
                isNullishOrEmpty(transaction.reference) ? null : (
                  <LakeCopyButton
                    valueToCopy={transaction.reference}
                    copyText={t("copyButton.copyTooltip")}
                    copiedText={t("copyButton.copiedTooltip")}
                  />
                )
              }
            />

            {isNotNullishOrEmpty(transaction.externalReference) ? (
              <LakeLabel
                type="view"
                label={t("transaction.externalReference")}
                render={() => (
                  <LakeText color={colors.gray[900]}>{transaction.externalReference}</LakeText>
                )}
                actions={
                  <LakeCopyButton
                    valueToCopy={transaction.externalReference}
                    copyText={t("copyButton.copyTooltip")}
                    copiedText={t("copyButton.copiedTooltip")}
                  />
                }
              />
            ) : null}

            <LakeLabel
              type="view"
              label={t("transaction.bookedBalanceAfter")}
              render={() => {
                if (isNullish(transaction.bookedBalanceAfter)) {
                  return UNKNOWN_VALUE;
                }

                const value = Number(transaction.bookedBalanceAfter.value);

                return (
                  <LakeText
                    variant="medium"
                    color={
                      value > 0
                        ? colors.positive.primary
                        : value < 0
                          ? colors.negative.primary
                          : colors.gray[900]
                    }
                  >
                    {value >= 0 && "+"}
                    {formatCurrency(value, transaction.bookedBalanceAfter.currency)}
                  </LakeText>
                );
              }}
            />

            {isNotNullish(payment) ? (
              <LakeLabel
                type="view"
                label={t("transaction.payment")}
                render={() => <LakeText color={colors.gray[900]}>{payment.id}</LakeText>}
                actions={
                  <LakeCopyButton
                    valueToCopy={payment.id}
                    copyText={t("copyButton.copyTooltip")}
                    copiedText={t("copyButton.copiedTooltip")}
                  />
                }
              />
            ) : null}
          </ReadOnlyFieldList>
        </Tile>

        {match(transaction)
          .with(
            {
              statusInfo: { status: "Upcoming" },
              type: P.union(
                "InternalCreditTransferOut",
                "SepaCreditTransferOut",
                "SepaInstantCreditTransferOut",
              ),
            },
            () => (
              <>
                <Space height={24} />

                <TrackPressable action="Cancel transaction">
                  <LakeButton
                    size="small"
                    style={styles.cancelTransaction}
                    color="negative"
                    mode="secondary"
                    icon="subtract-circle-regular"
                    onPress={openCancelTransaction}
                  >
                    {t("transaction.cancel")}
                  </LakeButton>
                </TrackPressable>
              </>
            ),
          )
          .otherwise(() => null)}

        {match(transaction)
          .with(
            { __typename: "CardTransaction", cardDetails: { __typename: "CardOutDetails" } },
            ({ authorizationType, cardDetails, merchant, originalAmount, terminalId }) => (
              <Tile title={t("transaction.cardTransaction")}>
                <ReadOnlyFieldList>
                  {match(cardDetails)
                    .with({ __typename: "CardOutDetails" }, cardOutDetails => {
                      const { card } = cardOutDetails;
                      return (
                        <ReadOnlyFieldList>
                          <LakeLabel
                            type="view"
                            label={t("transaction.card")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>
                                {isNullish(card?.name)
                                  ? printMaskedPanFormat(cardOutDetails.maskedPan)
                                  : `${card.name} - ${printMaskedPanFormat(cardOutDetails.maskedPan)}`}
                              </LakeText>
                            )}
                            actions={
                              card != null ? (
                                <Link
                                  to={Router.CardDetailRoot({
                                    projectId,
                                    projectEnv,
                                    cardId: card.id,
                                  })}
                                >
                                  <Icon size={20} name="arrow-right-filled" />
                                </Link>
                              ) : null
                            }
                          />

                          {card != null ? (
                            <LakeLabel
                              type="view"
                              label={t("transaction.cardHolder")}
                              render={() => {
                                const user = card.accountMembership.user;

                                return isNotNullish(user) && isNotNullish(user.fullName) ? (
                                  <LakeText color={colors.gray[900]}>{user.fullName}</LakeText>
                                ) : (
                                  UNKNOWN_VALUE
                                );
                              }}
                            />
                          ) : null}

                          <LakeLabel
                            type="view"
                            label={t("transaction.authorizationType")}
                            render={() =>
                              match(authorizationType)
                                .with("Classic", value => <Tag color="positive">{value}</Tag>)
                                .with("PreAuthorization", value => (
                                  <Tag color="shakespear">{value}</Tag>
                                ))
                                .with("DataRequest", value => <Tag color="gray">{value}</Tag>)
                                .with(P.nullish, () => UNKNOWN_VALUE)
                                .exhaustive()
                            }
                          />

                          <LakeLabel
                            type="view"
                            label={t("transaction.originalAmount")}
                            render={() => {
                              const unsigned = Number(originalAmount.value);
                              const value =
                                unsigned *
                                (unsigned === 0
                                  ? 1
                                  : match(transaction.side)
                                      .with("Debit", () => -1)
                                      .with("Credit", () => 1)
                                      .exhaustive());
                              return (
                                <LakeText
                                  style={{
                                    color:
                                      unsigned === 0
                                        ? colors.gray[900]
                                        : transaction.side === "Credit"
                                          ? colors.positive.primary
                                          : colors.negative.primary,
                                  }}
                                >
                                  {value > 0 && "+"}
                                  {formatCurrency(value, originalAmount.currency)}
                                </LakeText>
                              );
                            }}
                          />
                        </ReadOnlyFieldList>
                      );
                    })
                    .otherwise(() => null)}

                  {match(merchant)
                    .with(
                      { __typename: "CardOutMerchant" },
                      ({
                        category,
                        merchantName,
                        merchantId,
                        merchantCategoryCode,
                        merchantCategoryDescription,
                        merchantCity,
                        merchantCountry,
                      }) => (
                        <ReadOnlyFieldList>
                          <LakeLabel
                            type="view"
                            label={t("transaction.category")}
                            render={() => (
                              <View style={styles.line}>
                                {match(category)
                                  .with("InStore", value => <Tag color="gray">{value}</Tag>)
                                  .with("Other", value => <Tag color="gray">{value}</Tag>)
                                  .with("Withdrawal", value => <Tag color="gray">{value}</Tag>)
                                  .with("eCommerce", value => <Tag color="gray">{value}</Tag>)
                                  .with("eCommerceWith3DS", value => (
                                    <Tag color="gray">{value}</Tag>
                                  ))
                                  .exhaustive()}

                                <Space width={16} />
                                <InformationTooltip text={tTransactionCategory(category)} />
                              </View>
                            )}
                          />

                          <LakeLabel
                            type="view"
                            label={t("transaction.merchantName")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>{merchantName}</LakeText>
                            )}
                          />

                          <LakeLabel
                            type="view"
                            label={t("transaction.merchantId")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>{merchantId}</LakeText>
                            )}
                          />

                          <LakeLabel
                            type="view"
                            label={t("transaction.merchantCategoryCode")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>{merchantCategoryCode}</LakeText>
                            )}
                          />

                          <LakeLabel
                            type="view"
                            label={t("transaction.merchantCategoryDescription")}
                            render={() => {
                              return <Tag color="gray">{merchantCategoryDescription}</Tag>;
                            }}
                          />

                          <LakeLabel
                            type="view"
                            label={t("transaction.merchantCity")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>{merchantCity}</LakeText>
                            )}
                          />

                          <LakeLabel
                            type="view"
                            label={t("transaction.merchantCountry")}
                            render={() => {
                              if (isNotNullish(merchantCountry)) {
                                const countryName = countries.find(
                                  country => country.cca3 === merchantCountry,
                                )?.name;

                                return (
                                  <LakeText color={colors.gray[900]}>
                                    {isNotNullishOrEmpty(countryName)
                                      ? countryName
                                      : merchantCountry}
                                  </LakeText>
                                );
                              } else {
                                return UNKNOWN_VALUE;
                              }
                            }}
                          />

                          <LakeLabel
                            type="view"
                            label={t("transaction.terminalId")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>
                                {terminalId ?? UNKNOWN_VALUE}
                              </LakeText>
                            )}
                          />
                        </ReadOnlyFieldList>
                      ),
                    )
                    .otherwise(() => null)}
                </ReadOnlyFieldList>
              </Tile>
            ),
          )
          .with({ __typename: "InternalCreditTransfer" }, transaction => {
            const debtorAccount = transaction.debtor;
            const creditorAccount = transaction.creditor;
            return (
              <Tile title={t("transaction.internal")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("transaction.debtorAccount")}
                    render={() => (
                      <LakeText
                        color={colors.gray[900]}
                      >{`${debtorAccount.accountNumber} - ${debtorAccount.name}`}</LakeText>
                    )}
                  />

                  <LakeLabel
                    type="view"
                    label={t("transaction.creditorAccount")}
                    render={() => (
                      <LakeText
                        color={colors.gray[900]}
                      >{`${creditorAccount.accountNumber} - ${creditorAccount.name}`}</LakeText>
                    )}
                  />
                </ReadOnlyFieldList>
              </Tile>
            );
          })
          .with({ __typename: "SEPACreditTransferTransaction" }, transaction => (
            <Tile title={t("transaction.sepaCreditTransfer")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  type="view"
                  label={t("transaction.debtorName")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>{transaction.debtor.name}</LakeText>
                  )}
                />

                {match(transaction)
                  .with({ debtor: { IBAN: P.string } }, transaction => (
                    <LakeLabel
                      type="view"
                      label={t("transaction.debtorIban")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {printIbanFormat(transaction.debtor.IBAN)}
                        </LakeText>
                      )}
                    />
                  ))
                  .otherwise(() => null)}

                <LakeLabel
                  type="view"
                  label={t("transaction.creditorName")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>{transaction.creditor.name}</LakeText>
                  )}
                />

                {match(transaction)
                  .with({ creditor: { IBAN: P.string } }, transaction => (
                    <LakeLabel
                      type="view"
                      label={t("transaction.creditorIban")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {printIbanFormat(transaction.creditor.IBAN)}
                        </LakeText>
                      )}
                    />
                  ))
                  .otherwise(() => null)}

                {match(transaction.creditor)
                  .with(
                    { __typename: "SEPACreditTransferInCreditor" },
                    { __typename: "SEPACreditTransferInternalOutCreditor" },
                    creditor => {
                      const virtualIBANEntryId = creditor.virtualIBANEntryId;
                      return isNotNullish(virtualIBANEntryId) ? (
                        <LakeLabel
                          type="view"
                          label={t("transaction.virtualIbanEntryId")}
                          render={() => (
                            <LakeText color={colors.gray[900]}>{virtualIBANEntryId}</LakeText>
                          )}
                          actions={
                            <LakeCopyButton
                              valueToCopy={virtualIBANEntryId}
                              copyText={t("copyButton.copyTooltip")}
                              copiedText={t("copyButton.copiedTooltip")}
                            />
                          }
                        />
                      ) : null;
                    },
                  )
                  .with({ __typename: "SEPACreditTransferOutCreditor" }, () => null)
                  .otherwise(() => null)}
              </ReadOnlyFieldList>
            </Tile>
          ))
          .with({ __typename: "SEPADirectDebitTransaction" }, transaction => {
            const virtualIBANEntryId = transaction.debtor.virtualIBANEntryId;
            const mandate = transaction.mandate;

            return (
              <Tile title={t("transaction.sepaDirectDebit")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("transaction.debtorName")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>{transaction.debtor.name}</LakeText>
                    )}
                  />

                  {match(transaction)
                    .with({ debtor: { IBAN: P.string } }, transaction => (
                      <LakeLabel
                        type="view"
                        label={t("transaction.debtorIban")}
                        render={() => (
                          <LakeText color={colors.gray[900]}>
                            {printIbanFormat(transaction.debtor.IBAN)}
                          </LakeText>
                        )}
                      />
                    ))
                    .otherwise(() => null)}

                  {isNotNullish(virtualIBANEntryId) ? (
                    <LakeLabel
                      type="view"
                      label={t("transaction.virtualIbanEntryId")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>{virtualIBANEntryId}</LakeText>
                      )}
                      actions={
                        <LakeCopyButton
                          valueToCopy={virtualIBANEntryId}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />
                      }
                    />
                  ) : null}

                  <LakeLabel
                    type="view"
                    label={t("transaction.creditorName")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>{transaction.creditor.name}</LakeText>
                    )}
                  />

                  {match(transaction)
                    .with({ creditor: { IBAN: P.string } }, transaction => (
                      <LakeLabel
                        type="view"
                        label={t("transaction.creditorIban")}
                        render={() => (
                          <LakeText color={colors.gray[900]}>
                            {printIbanFormat(transaction.creditor.IBAN)}
                          </LakeText>
                        )}
                      />
                    ))
                    .otherwise(() => null)}

                  {isNotNullish(mandate)
                    ? match(mandate)
                        .with(
                          { __typename: "SEPAPaymentDirectDebitMandate" },
                          ({ reference, scheme }) => (
                            <ReadOnlyFieldList>
                              <LakeLabel
                                type="view"
                                label={t("transaction.mandateReference")}
                                render={() =>
                                  isNullishOrEmpty(reference) ? (
                                    UNKNOWN_VALUE
                                  ) : (
                                    <LakeText color={colors.gray[900]}>{reference}</LakeText>
                                  )
                                }
                              />

                              <LakeLabel
                                type="view"
                                label={t("transaction.mandateScheme")}
                                render={() =>
                                  match(scheme)
                                    .with("SepaDirectDebitB2b", () => (
                                      <Tag color="darkPink">
                                        {t("transaction.mandateScheme.b2b")}
                                      </Tag>
                                    ))
                                    .with("SepaDirectDebitCore", () => (
                                      <Tag color="live">{t("transaction.mandateScheme.core")}</Tag>
                                    ))
                                    .exhaustive()
                                }
                              />
                            </ReadOnlyFieldList>
                          ),
                        )
                        .with(
                          { __typename: "SEPAReceivedDirectDebitMandate" },
                          ({ receivedScheme, reference, receivedCreditor }) => (
                            <ReadOnlyFieldList>
                              <LakeLabel
                                type="view"
                                label={t("transaction.mandateCreditorIdentifier")}
                                render={() => (
                                  <LakeText color={colors.gray[900]}>
                                    {receivedCreditor.identifier}
                                  </LakeText>
                                )}
                              />

                              <LakeLabel
                                type="view"
                                label={t("transaction.mandateReference")}
                                render={() =>
                                  isNullishOrEmpty(reference) ? (
                                    UNKNOWN_VALUE
                                  ) : (
                                    <LakeText color={colors.gray[900]}>{reference}</LakeText>
                                  )
                                }
                              />

                              <LakeLabel
                                type="view"
                                label={t("transaction.mandateScheme")}
                                render={() =>
                                  match(receivedScheme)
                                    .with("SepaDirectDebitB2b", () => (
                                      <Tag color="darkPink">
                                        {t("transaction.mandateScheme.b2b")}
                                      </Tag>
                                    ))
                                    .with("SepaDirectDebitCore", () => (
                                      <Tag color="live">{t("transaction.mandateScheme.core")}</Tag>
                                    ))
                                    .exhaustive()
                                }
                              />
                            </ReadOnlyFieldList>
                          ),
                        )
                        .exhaustive()
                    : null}
                </ReadOnlyFieldList>
              </Tile>
            );
          })
          .with({ __typename: "FeeTransaction" }, ({ feesType, originTransaction }) => (
            <Tile title={t("transaction.fee")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  type="view"
                  label={t("transaction.counterparty")}
                  render={() =>
                    isNullishOrEmpty(transaction.counterparty) ? (
                      UNKNOWN_VALUE
                    ) : (
                      <LakeText color={colors.gray[900]}>{transaction.counterparty}</LakeText>
                    )
                  }
                />

                {isNotNullish(originTransaction) && (
                  <ReadOnlyFieldList>
                    <LakeLabel
                      type="viewSmall"
                      label={t("transaction.originalTransactionId")}
                      render={() => (
                        <LakeText variant="regular" color={colors.gray[900]}>
                          {originTransaction.id}
                        </LakeText>
                      )}
                      actions={
                        <LakeCopyButton
                          valueToCopy={originTransaction.id}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />
                      }
                    />

                    <LakeLabel
                      type="viewSmall"
                      label={t("transaction.originalTransactionDate")}
                      render={() => (
                        <LakeText variant="regular" color={colors.gray[900]}>
                          {dayjs(originTransaction.executionDate).format(
                            `${locale.dateFormat} ${locale.timeFormat}`,
                          )}
                        </LakeText>
                      )}
                    />

                    {match(feesType)
                      .with("CashWithdrawalsOutsideSEPA", "CardPaymentsOutsideSEPA", () => (
                        <LakeLabel
                          type="viewSmall"
                          label={t("transaction.originalTransactionAmount")}
                          render={() => (
                            <LakeText variant="regular" color={colors.gray[900]}>
                              {formatCurrency(
                                Number(originTransaction.amount.value),
                                originTransaction.amount.currency,
                              )}
                            </LakeText>
                          )}
                        />
                      ))
                      .with("DirectDebitRejection", () => (
                        <LakeLabel
                          type="viewSmall"
                          label={t("transaction.rejectedAmount")}
                          render={() => (
                            <LakeText variant="regular" color={colors.gray[900]}>
                              {formatCurrency(
                                Number(originTransaction.amount.value),
                                originTransaction.amount.currency,
                              )}
                            </LakeText>
                          )}
                        />
                      ))
                      .otherwise(() => null)}

                    {match(transaction)
                      .with(
                        {
                          __typename: "FeeTransaction",
                          feesType: "DirectDebitRejection",
                          originTransaction: {
                            statusInfo: {
                              __typename: "RejectedTransactionStatusInfo",
                              reason: P.select(),
                            },
                          },
                        },
                        reason => {
                          const description = getTransactionRejectedReasonLabel(reason);

                          if (isNullish(description)) {
                            return null;
                          }

                          return (
                            <LakeLabel
                              type="viewSmall"
                              label={t("transaction.feesReason")}
                              render={() => (
                                <LakeText variant="regular" color={colors.gray[900]}>
                                  {description}
                                </LakeText>
                              )}
                            />
                          );
                        },
                      )
                      .otherwise(() => null)}
                  </ReadOnlyFieldList>
                )}
              </ReadOnlyFieldList>
            </Tile>
          ))
          .with({ __typename: "InternalDirectDebitTransaction" }, transaction => (
            <Tile title={t("transaction.internalDirectDebit")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  type="view"
                  label={t("transaction.creditorAccount")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>{transaction.creditor.accountId}</LakeText>
                  )}
                />

                {match(transaction.mandate)
                  .with(P.nullish, () => null)
                  .with(
                    { __typename: "InternalPaymentDirectDebitMandate" },
                    { __typename: "InternalReceivedDirectDebitMandate" },
                    ({ id }) => (
                      <ReadOnlyFieldList>
                        <LakeLabel
                          type="view"
                          label={t("transaction.id")}
                          render={() => <LakeText color={colors.gray[900]}>{id}</LakeText>}
                        />
                      </ReadOnlyFieldList>
                    ),
                  )
                  .exhaustive()}
              </ReadOnlyFieldList>
            </Tile>
          ))
          .with({ __typename: "CheckTransaction" }, transaction => {
            // The check number is the first 7 numbers of the cmc7
            const checkNumber = transaction.cmc7.slice(0, 7);

            return (
              <Tile title={t("transaction.paymentProduct.Check")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("transaction.creditorAccount")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>{transaction.account?.id}</LakeText>
                    )}
                    actions={
                      <LakeCopyButton
                        valueToCopy={
                          isNotNullish(transaction.account) ? transaction.account.id : ""
                        }
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("transaction.id")}
                    render={() => <LakeText color={colors.gray[900]}>{transaction.id}</LakeText>}
                    actions={
                      <LakeCopyButton
                        valueToCopy={transaction.id}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("transaction.cmc7")}
                    render={() => <LakeText color={colors.gray[900]}>{transaction.cmc7}</LakeText>}
                    actions={
                      <LakeCopyButton
                        valueToCopy={transaction.cmc7}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("transaction.rlmcKey")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>{transaction.rlmcKey}</LakeText>
                    )}
                    actions={
                      <LakeCopyButton
                        valueToCopy={transaction.rlmcKey}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("transaction.checkNumber")}
                    render={() => <LakeText color={colors.gray[900]}>{checkNumber}</LakeText>}
                    actions={
                      <LakeCopyButton
                        valueToCopy={checkNumber}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />
                </ReadOnlyFieldList>
              </Tile>
            );
          })
          .with(
            { __typename: "InternationalCreditTransferTransaction" },
            ({ creditor, currencyExchange, statusInfo }) => {
              return match(creditor)
                .with(
                  {
                    __typename: "InternationalCreditTransferOutCreditor",
                  },
                  ({ name, details }) => (
                    <Tile title={t("transaction.paymentProduct.InternationalCreditTransfer")}>
                      <ReadOnlyFieldList>
                        <LakeLabel
                          type="view"
                          label={t("transaction.internationalCreditTransfer.name")}
                          render={() => <LakeText color={colors.gray[900]}>{name}</LakeText>}
                        />

                        {details.map(detail => (
                          <LakeLabel
                            key={getWiseIctLabel(detail.key)}
                            type="viewSmall"
                            label={getWiseIctLabel(detail.key)}
                            render={() => (
                              <LakeText variant="regular" color={colors.gray[900]}>
                                {getWiseIctLabel(detail.value)}
                              </LakeText>
                            )}
                          />
                        ))}

                        <LakeLabel
                          type="view"
                          label={t("transaction.internationalCreditTransfer.exchangeRate")}
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {currencyExchange.exchangeRate}
                            </LakeText>
                          )}
                        />

                        {match(statusInfo)
                          .with({ __typename: "RejectedTransactionStatusInfo" }, ({ reason }) => (
                            <LakeLabel
                              type="viewSmall"
                              label={t("transaction.rejectedReason")}
                              render={() => (
                                <LakeText variant="regular" color={colors.gray[900]}>
                                  {getTransactionRejectedReasonLabel(reason)}
                                </LakeText>
                              )}
                            />
                          ))
                          .otherwise(() => null)}
                      </ReadOnlyFieldList>
                    </Tile>
                  ),
                )
                .otherwise(() => null);
            },
          )
          .with(
            { __typename: "CardTransaction", merchant: { __typename: "CardInMerchant" } },
            ({ merchant }) => (
              <Tile title={t("transaction.merchant")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("transaction.merchantId")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>{merchant.merchantProfile.id}</LakeText>
                    )}
                    actions={
                      <LakeCopyButton
                        valueToCopy={merchant.merchantProfile.id}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("transaction.merchantName")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {merchant.merchantProfile.merchantName}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    type="view"
                    label={t("transaction.merchantWebsite")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {merchant.merchantProfile.merchantWebsite}
                      </LakeText>
                    )}
                  />
                </ReadOnlyFieldList>
              </Tile>
            ),
          )
          .otherwise(() => null)}

        <Tile title={t("transaction.account")}>
          <ReadOnlyFieldList>
            <LakeLabel
              type="view"
              label={t("transaction.accountHolder")}
              render={() => {
                return isNotNullish(account) ? (
                  <LakeText color={colors.gray[900]}>{account.holder.info.name}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                );
              }}
              actions={
                isNotNullish(account) ? (
                  <Link
                    to={Router.HoldersDetailRoot({
                      projectId,
                      projectEnv,
                      accountHolderId: account.holder.id,
                    })}
                  >
                    <Icon size={20} name="arrow-right-filled" />
                  </Link>
                ) : null
              }
            />

            <LakeLabel
              type="view"
              label={t("transaction.account")}
              render={() => {
                const account = transaction.account;
                return isNotNullish(account) ? (
                  <LakeText
                    color={colors.gray[900]}
                  >{`${account.number} - ${account.name}`}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                );
              }}
              actions={
                isNotNullish(account) ? (
                  <Link
                    to={Router.AccountDetailRoot({
                      projectId,
                      projectEnv,
                      accountId: account.id,
                    })}
                  >
                    <Icon size={20} name="arrow-right-filled" />
                  </Link>
                ) : null
              }
            />
          </ReadOnlyFieldList>
        </Tile>

        {match(transaction)
          .with(
            {
              __typename: "CardTransaction",
              cardDetails: { __typename: "CardOutDetails" },
              enrichedTransactionInfo: P.select(P.nonNullable),
            },
            enrichedTransactionInfo => {
              const countryName = countries.find(
                country => country.cca3 === enrichedTransactionInfo.country,
              )?.name;

              return (
                <Tile title={t("transaction.enrichedTransactionInformation")}>
                  <ReadOnlyFieldList>
                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantName")}
                      render={() =>
                        enrichedTransactionInfo.enrichedMerchantName != null ? (
                          <LakeText color={colors.gray[900]} style={styles.merchantName}>
                            {match(enrichedTransactionInfo)
                              .with({ logoUrl: P.select(P.string) }, logoUrl => (
                                <>
                                  <Image source={logoUrl} style={styles.merchantLogo} />
                                  <Space width={8} />
                                </>
                              ))
                              .otherwise(() => null)}

                            <LakeText color={colors.gray[900]}>
                              {enrichedTransactionInfo.enrichedMerchantName}
                            </LakeText>
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantCategory")}
                      render={() =>
                        enrichedTransactionInfo.category != null ? (
                          <Box direction="row" alignItems="center">
                            <Icon
                              name={getMerchantCategoryIcon(enrichedTransactionInfo.category)}
                              size={16}
                            />

                            <Space width={8} />

                            <LakeText color={colors.gray[900]}>
                              {enrichedTransactionInfo.category}
                            </LakeText>
                          </Box>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantSubcategory")}
                      render={() =>
                        enrichedTransactionInfo.subcategory != null ? (
                          <LakeText color={colors.gray[900]}>
                            {enrichedTransactionInfo.subcategory}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantWebsite")}
                      render={() =>
                        enrichedTransactionInfo.contactWebsite != null ? (
                          <LakeText color={colors.gray[900]}>
                            {enrichedTransactionInfo.contactWebsite}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantPhoneNumber")}
                      render={() =>
                        enrichedTransactionInfo.contactPhone != null ? (
                          <LakeText color={colors.gray[900]}>
                            {enrichedTransactionInfo.contactPhone}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantEmail")}
                      render={() =>
                        enrichedTransactionInfo.contactEmail != null ? (
                          <LakeText color={colors.gray[900]}>
                            {enrichedTransactionInfo.contactEmail}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantAddress")}
                      render={() =>
                        enrichedTransactionInfo.address != null ? (
                          <LakeText color={colors.gray[900]}>
                            {enrichedTransactionInfo.address}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantPostalCode")}
                      render={() =>
                        enrichedTransactionInfo.postalCode != null ? (
                          <LakeText color={colors.gray[900]}>
                            {enrichedTransactionInfo.postalCode}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantCity.enriched")}
                      render={() =>
                        enrichedTransactionInfo.city != null ? (
                          <LakeText color={colors.gray[900]}>
                            {enrichedTransactionInfo.city}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.merchantCountry.enriched")}
                      render={() =>
                        countryName != null ? (
                          <LakeText color={colors.gray[900]}>{countryName}</LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.isSubscription")}
                      render={() =>
                        match(enrichedTransactionInfo.isSubscription)
                          .with(true, () => <Tag color="positive">{t("common.true")}</Tag>)
                          .with(false, () => <Tag color="gray">{t("common.false")}</Tag>)
                          .otherwise(() => UNKNOWN_VALUE)
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("transaction.carbonFootprint")}
                      render={() =>
                        enrichedTransactionInfo.carbonFootprint != null ? (
                          <LakeText color={colors.gray[900]}>
                            {t("transaction.carbonFootprint.value", {
                              carbonFootprint:
                                Number(enrichedTransactionInfo.carbonFootprint) / 1_000_000,
                            })}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />
                  </ReadOnlyFieldList>
                </Tile>
              );
            },
          )
          .otherwise(() => null)}
      </TileGrid>

      <Space height={24} />

      <LakeModal visible={cancelTransactionVisible}>
        <CancelTransactionModal
          transactionId={transaction.id}
          onClose={() => {
            reload();
            closeCancelTransaction();
          }}
        />
      </LakeModal>
    </LakeScrollView>
  );
};
