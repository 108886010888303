const readOnlyLanguages = [
  {
    name: "English",
    native: "English",
    cca3: "USA",
    id: "en",
    flag: "🇺🇸",
  },
  {
    name: "French",
    native: "Français",
    id: "fr",
    cca3: "FRA",
    flag: "🇫🇷",
  },
  {
    name: "Italian",
    native: "Italiano",
    id: "it",
    cca3: "ITA",
    flag: "🇮🇹",
  },
  {
    name: "Dutch",
    native: "Nederlands",
    id: "nl",
    cca3: "NLD",
    flag: "🇳🇱",
  },
  {
    name: "German",
    native: "Deutsch",
    id: "de",
    cca3: "DEU",
    flag: "🇧🇪",
  },
  {
    name: "Spanish",
    native: "Español",
    id: "es",
    cca3: "ESP",
    flag: "🇪🇸",
  },
  {
    name: "Portuguese",
    native: "Português",
    id: "pt",
    cca3: "PRT",
    flag: "🇵🇹",
  },
  {
    name: "Finnish",
    native: "Suomi",
    id: "fi",
    cca3: "FIN",
    flag: "🇫🇮",
  },
] as const;

export const languages = [...readOnlyLanguages];
